<template>
  <div>
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Sign Out</p>
        <button class="delete" aria-label="close" @click="closeModal"></button>
      </header>
      <section class="modal-card-body">
        <p>Are you sure you want to sign out?</p>
        <br />
      </section>
      <footer class="modal-card-foot">
        <button class="button is-danger" @click="signOut">Yes</button>
        <button class="button is-success" @click="closeModal">No</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalLogin',
  components: {},
  props: {
    showModal: Boolean,
  },
  created() {},
  methods: {
    signOut() {
      this.$store.dispatch('auth/logoutUser')
      this.$store.dispatch('modal/toggleModal')
    },
    closeModal() {
      this.$store.dispatch('modal/toggleModal')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
