import axios from 'axios'

const userService = axios.create({
  baseURL: 'http://localhost:8000/users',
  withCredentials: true,
  // xsrfCookieName: 'csrf_access_token',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export default {
  createUser(userCreate) {
    console.log(userCreate)
    return userService.post('/', userCreate)
  },
  getUserByStych(stychId) {
    return userService.get(stychId + '/stych')
  },
}
