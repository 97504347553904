import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { AllUniconIcons } from './assets/icons/MyUnicon'

createApp(App)
  .use(store)
  .use(router)
  .use(AllUniconIcons, {
    fill: 'black',
    height: 24,
    width: 24,
  })
  .mount('#app')
