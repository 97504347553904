<template>
  <div>
    <p>
      This is the authentication routed page. this is your token: {{ token }}
    </p>
  </div>
</template>

<script>
import UserService from '@/services/UserService.js'

export default {
  name: 'AuthPage',
  components: {},
  props: ['id'],
  data() {
    return {
      token: null,
      stychRes: null,
      stychUser: null,
      user: null,
    }
  },
  async created() {
    // if magic link succeds, store session token, stych_id and stych_email at store.
    // if it fails, redirect to home
    this.token = this.$route.query.token
    this.$store.dispatch('auth/toSession', this.token)
    //
    // check if user exists, by mapping stych_id to a valid DB user registry
    this.user = await UserService.getUserByStych(this.$store.state.auth.stychId)
    //
    // if user.data is not None proceed with login
    if (this.user.data) {
      this.$store.dispatch('auth/loginUser', this.user)
      this.$router.replace({ name: 'Home' })
    } else {
      // else, stych was created, but morales user is still pending
      this.$router.replace({ name: 'CreateUser' })
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
