<template>
  <div>
    <Navbar @toggleModal="toggleModal" />
    <router-view />
    <ModalContainer />
    <Footer />
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'
import ModalContainer from './components/modals/ModalContainer.vue'
export default {
  components: { Navbar, Footer, ModalContainer },
  name: 'App',
  props: {
    msg: String,
  },
  data() {
    return {
      showModal: false,
    }
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal
    },
  },
}
</script>

<style lang="scss">
#app {
  font-family: $font-monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $primarycolor;
}

#nav {
  padding: 30px;
  text-align: center;
}

#nav a {
  font-weight: bold;
  color: $primarycolor;
}

#nav a.router-link-exact-active {
  color: $secondarycolor;
}
</style>
