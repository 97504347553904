import Unicon from 'vue-unicons'
import {
  uni0Plus,
  uni10Plus,
  uni12Plus,
  uni13Plus,
  uni16Plus,
  uni17Plus,
  uni18Plus,
  uni21Plus,
  uni3Plus,
  uni500Px,
  uni6Plus,
  uniAbacus,
  uniAccessibleIconAlt,
  uniAdjust,
  uniAdjustAlt,
  uniAdjustCircle,
  uniAdjustHalf,
  uniAdobe,
  uniAdobeAlt,
  uniAirplay,
  uniAlign,
  uniAlignAlt,
  uniAlignCenter,
  uniAlignCenterAlt,
  uniAlignCenterH,
  uniAlignCenterJustify,
  uniAlignCenterV,
  uniAlignJustify,
  uniAlignLeft,
  uniAlignLeftJustify,
  uniAlignLetterRight,
  uniAlignRight,
  uniAlignRightJustify,
  uniAmazon,
  uniAmbulance,
  uniAnalysis,
  uniAnalytics,
  uniAnchor,
  uniAndroid,
  uniAndroidAlt,
  uniAndroidPhoneSlash,
  uniAngleDoubleDown,
  uniAngleDoubleLeft,
  uniAngleDoubleRight,
  uniAngleDoubleUp,
  uniAngleDown,
  uniAngleLeft,
  uniAngleLeftB,
  uniAngleRight,
  uniAngleRightB,
  uniAngleUp,
  uniAngry,
  uniAnkh,
  uniAnnoyed,
  uniAnnoyedAlt,
  uniApple,
  uniAppleAlt,
  uniApps,
  uniArchive,
  uniArchiveAlt,
  uniArchway,
  uniArrow,
  uniArrowBreak,
  uniArrowCircleDown,
  uniArrowCircleLeft,
  uniArrowCircleRight,
  uniArrowCircleUp,
  uniArrowCompressH,
  uniArrowDown,
  uniArrowDownLeft,
  uniArrowDownRight,
  uniArrowFromRight,
  uniArrowFromTop,
  uniArrowGrowth,
  uniArrowLeft,
  uniArrowRandom,
  uniArrowResizeDiagonal,
  uniArrowRight,
  uniArrowToBottom,
  uniArrowToRight,
  uniArrowUp,
  uniArrowUpLeft,
  uniArrowUpRight,
  uniArrowsH,
  uniArrowsHAlt,
  uniArrowsLeftDown,
  uniArrowsMaximize,
  uniArrowsMerge,
  uniArrowsResize,
  uniArrowsResizeH,
  uniArrowsResizeV,
  uniArrowsRightDown,
  uniArrowsShrinkH,
  uniArrowsShrinkV,
  uniArrowsUpRight,
  uniArrowsV,
  uniArrowsVAlt,
  uniAssistiveListeningSystems,
  uniAsterisk,
  uniAt,
  uniAtom,
  uniAutoFlash,
  uniAward,
  uniAwardAlt,
  uniBabyCarriage,
  uniBackpack,
  uniBackspace,
  uniBackward,
  uniBag,
  uniBagAlt,
  uniBagSlash,
  uniBalanceScale,
  uniBan,
  uniBandAid,
  uniBars,
  uniBaseballBall,
  uniBasketball,
  uniBasketballHoop,
  uniBath,
  uniBatteryBolt,
  uniBatteryEmpty,
  uniBed,
  uniBedDouble,
  uniBehance,
  uniBehanceAlt,
  uniBell,
  uniBellSchool,
  uniBellSlash,
  uniBill,
  uniBing,
  uniBitcoin,
  uniBitcoinAlt,
  uniBitcoinCircle,
  uniBitcoinSign,
  uniBlackBerry,
  uniBlogger,
  uniBloggerAlt,
  uniBluetoothB,
  uniBold,
  uniBolt,
  uniBoltAlt,
  uniBoltSlash,
  uniBook,
  uniBookAlt,
  uniBookMedical,
  uniBookOpen,
  uniBookReader,
  uniBookmark,
  uniBookmarkFull,
  uniBooks,
  uniBoombox,
  uniBorderAlt,
  uniBorderBottom,
  uniBorderClear,
  uniBorderHorizontal,
  uniBorderInner,
  uniBorderLeft,
  uniBorderOut,
  uniBorderRight,
  uniBorderTop,
  uniBorderVertical,
  uniBowlingBall,
  uniBox,
  uniBracketsCurly,
  uniBrain,
  uniBriefcase,
  uniBriefcaseAlt,
  uniBright,
  uniBrightness,
  uniBrightnessEmpty,
  uniBrightnessHalf,
  uniBrightnessLow,
  uniBrightnessMinus,
  uniBrightnessPlus,
  uniBringBottom,
  uniBringFront,
  uniBrowser,
  uniBrushAlt,
  uniBug,
  uniBuilding,
  uniBullseye,
  uniBus,
  uniBusAlt,
  uniBusSchool,
  uniCalculator,
  uniCalculatorAlt,
  uniCalendarAlt,
  uniCalendarSlash,
  uniCalender,
  uniCalling,
  uniCamera,
  uniCameraChange,
  uniCameraPlus,
  uniCameraSlash,
  uniCancel,
  uniCapsule,
  uniCapture,
  uniCar,
  uniCarSideview,
  uniCarSlash,
  uniCarWash,
  uniCardAtm,
  uniCaretRight,
  uniCell,
  uniCelsius,
  uniChannel,
  uniChannelAdd,
  uniChart,
  uniChartBar,
  uniChartBarAlt,
  uniChartDown,
  uniChartGrowth,
  uniChartGrowthAlt,
  uniChartLine,
  uniChartPie,
  uniChartPieAlt,
  uniChat,
  uniChatBubbleUser,
  uniChatInfo,
  uniCheck,
  uniCheckCircle,
  uniCheckSquare,
  uniCircle,
  uniCircleLayer,
  uniCircuit,
  uniClapperBoard,
  uniClinicMedical,
  uniClipboard,
  uniClipboardAlt,
  uniClipboardBlank,
  uniClipboardNotes,
  uniClock,
  uniClockEight,
  uniClockFive,
  uniClockNine,
  uniClockSeven,
  uniClockTen,
  uniClockThree,
  uniClockTwo,
  uniClosedCaptioning,
  uniClosedCaptioningSlash,
  uniCloud,
  uniCloudBlock,
  uniCloudBookmark,
  uniCloudCheck,
  uniCloudComputing,
  uniCloudDataConnection,
  uniCloudDatabaseTree,
  uniCloudDownload,
  uniCloudDrizzle,
  uniCloudExclamation,
  uniCloudHail,
  uniCloudHeart,
  uniCloudInfo,
  uniCloudLock,
  uniCloudMeatball,
  uniCloudMoon,
  uniCloudMoonHail,
  uniCloudMoonMeatball,
  uniCloudMoonRain,
  uniCloudMoonShowers,
  uniCloudQuestion,
  uniCloudRain,
  uniCloudRainSun,
  uniCloudRedo,
  uniCloudShare,
  uniCloudShield,
  uniCloudShowers,
  uniCloudShowersAlt,
  uniCloudShowersHeavy,
  uniCloudSlash,
  uniCloudSun,
  uniCloudSunHail,
  uniCloudSunMeatball,
  uniCloudSunRain,
  uniCloudSunRainAlt,
  uniCloudSunTear,
  uniCloudTimes,
  uniCloudUnlock,
  uniCloudUpload,
  uniCloudWifi,
  uniCloudWind,
  uniClouds,
  uniClub,
  uniCodeBranch,
  uniCoffee,
  uniCog,
  uniCoins,
  uniColumns,
  uniComment,
  uniCommentAdd,
  uniCommentAlt,
  uniCommentAltBlock,
  uniCommentAltChartLines,
  uniCommentAltCheck,
  uniCommentAltDots,
  uniCommentAltDownload,
  uniCommentAltEdit,
  uniCommentAltExclamation,
  uniCommentAltHeart,
  uniCommentAltImage,
  uniCommentAltInfo,
  uniCommentAltLines,
  uniCommentAltLock,
  uniCommentAltMedical,
  uniCommentAltMessage,
  uniCommentAltNotes,
  uniCommentAltPlus,
  uniCommentAltQuestion,
  uniCommentAltRedo,
  uniCommentAltSearch,
  uniCommentAltShare,
  uniCommentAltShield,
  uniCommentAltSlash,
  uniCommentAltUpload,
  uniCommentAltVerify,
  uniCommentBlock,
  uniCommentChartLine,
  uniCommentCheck,
  uniCommentDots,
  uniCommentDownload,
  uniCommentEdit,
  uniCommentExclamation,
  uniCommentHeart,
  uniCommentImage,
  uniCommentInfo,
  uniCommentInfoAlt,
  uniCommentLines,
  uniCommentLock,
  uniCommentMedical,
  uniCommentMessage,
  uniCommentNotes,
  uniCommentPlus,
  uniCommentQuestion,
  uniCommentRedo,
  uniCommentSearch,
  uniCommentShare,
  uniCommentShield,
  uniCommentSlash,
  uniCommentUpload,
  uniCommentVerify,
  uniComments,
  uniCommentsAlt,
  uniCompactDisc,
  uniComparison,
  uniCompass,
  uniCompress,
  uniCompressAlt,
  uniCompressAltLeft,
  uniCompressArrows,
  uniCompressLines,
  uniCompressPoint,
  uniCompressV,
  uniConfused,
  uniConstructor,
  uniCopy,
  uniCopyAlt,
  uniCopyLandscape,
  uniCopyright,
  uniCornerDownLeft,
  uniCornerDownRight,
  uniCornerDownRightAlt,
  uniCornerLeftDown,
  uniCornerRightDown,
  uniCornerUpLeft,
  uniCornerUpLeftAlt,
  uniCornerUpRight,
  uniCornerUpRightAlt,
  uniCoronavirus,
  uniCreateDashboard,
  uniCreativeCommonsPd,
  uniCreditCard,
  uniCreditCardSearch,
  uniCrockery,
  uniCropAlt,
  uniCropAltRotateLeft,
  uniCropAltRotateRight,
  uniCrosshair,
  uniCrosshairAlt,
  uniCrosshairs,
  uniCss3Simple,
  uniCube,
  uniDashboard,
  uniDataSharing,
  uniDatabase,
  uniDatabaseAlt,
  uniDesert,
  uniDesktop,
  uniDesktopAlt,
  uniDesktopAltSlash,
  uniDesktopCloudAlt,
  uniDesktopSlash,
  uniDialpad,
  uniDialpadAlt,
  uniDiamond,
  uniDiary,
  uniDiaryAlt,
  uniDiceFive,
  uniDiceFour,
  uniDiceOne,
  uniDiceSix,
  uniDiceThree,
  uniDiceTwo,
  uniDirection,
  uniDirections,
  uniDiscord,
  uniDizzyMeh,
  uniDna,
  uniDocker,
  uniDocumentInfo,
  uniDocumentLayoutCenter,
  uniDocumentLayoutLeft,
  uniDocumentLayoutRight,
  uniDollarAlt,
  uniDollarSign,
  uniDollarSignAlt,
  uniDownloadAlt,
  uniDraggabledots,
  uniDribbble,
  uniDrill,
  uniDropbox,
  uniDumbbell,
  uniEar,
  uniEdit,
  uniEditAlt,
  uniElipsisDoubleVAlt,
  uniEllipsisH,
  uniEllipsisV,
  uniEmoji,
  uniEnglishToChinese,
  uniEnter,
  uniEnvelope,
  uniEnvelopeAdd,
  uniEnvelopeAlt,
  uniEnvelopeBlock,
  uniEnvelopeBookmark,
  uniEnvelopeCheck,
  uniEnvelopeDownload,
  uniEnvelopeDownloadAlt,
  uniEnvelopeEdit,
  uniEnvelopeExclamation,
  uniEnvelopeHeart,
  uniEnvelopeInfo,
  uniEnvelopeLock,
  uniEnvelopeMinus,
  uniEnvelopeOpen,
  uniEnvelopeQuestion,
  uniEnvelopeReceive,
  uniEnvelopeRedo,
  uniEnvelopeSearch,
  uniEnvelopeSend,
  uniEnvelopeShare,
  uniEnvelopeShield,
  uniEnvelopeStar,
  uniEnvelopeTimes,
  uniEnvelopeUpload,
  uniEnvelopeUploadAlt,
  uniEnvelopes,
  uniEqualCircle,
  uniEstate,
  uniEuro,
  uniEuroCircle,
  uniExchange,
  uniExchangeAlt,
  uniExclamation,
  uniExclamationCircle,
  uniExclamationOctagon,
  uniExclamationTriangle,
  uniExclude,
  uniExpandAlt,
  uniExpandArrows,
  uniExpandArrowsAlt,
  uniExpandFromCorner,
  uniExpandLeft,
  uniExpandRight,
  uniExport,
  uniExposureAlt,
  uniExposureIncrease,
  uniExternalLinkAlt,
  uniEye,
  uniEyeSlash,
  uniFacebook,
  uniFacebookF,
  uniFacebookMessenger,
  uniFacebookMessengerAlt,
  uniFahrenheit,
  uniFastMail,
  uniFastMailAlt,
  uniFavorite,
  uniFeedback,
  uniFidgetSpinner,
  uniFile,
  uniFileAlt,
  uniFileBlank,
  uniFileBlockAlt,
  uniFileBookmarkAlt,
  uniFileCheck,
  uniFileCheckAlt,
  uniFileContract,
  uniFileContractDollar,
  uniFileCopyAlt,
  uniFileDownload,
  uniFileDownloadAlt,
  uniFileEditAlt,
  uniFileExclamation,
  uniFileExclamationAlt,
  uniFileExport,
  uniFileGraph,
  uniFileHeart,
  uniFileImport,
  uniFileInfoAlt,
  uniFileLandscape,
  uniFileLandscapeAlt,
  uniFileLanscapeSlash,
  uniFileLockAlt,
  uniFileMedical,
  uniFileMedicalAlt,
  uniFileMinus,
  uniFileMinusAlt,
  uniFileNetwork,
  uniFilePlus,
  uniFilePlusAlt,
  uniFileQuestion,
  uniFileQuestionAlt,
  uniFileRedoAlt,
  uniFileSearchAlt,
  uniFileShareAlt,
  uniFileShieldAlt,
  uniFileSlash,
  uniFileTimes,
  uniFileTimesAlt,
  uniFileUpload,
  uniFileUploadAlt,
  uniFilesLandscapes,
  uniFilesLandscapesAlt,
  uniFilm,
  uniFilter,
  uniFilterSlash,
  uniFire,
  uniFlask,
  uniFlaskPotion,
  uniFlipH,
  uniFlipHAlt,
  uniFlipV,
  uniFlipVAlt,
  uniFlower,
  uniFocus,
  uniFocusAdd,
  uniFocusTarget,
  uniFolder,
  uniFolderCheck,
  uniFolderDownload,
  uniFolderExclamation,
  uniFolderHeart,
  uniFolderInfo,
  uniFolderLock,
  uniFolderMedical,
  uniFolderMinus,
  uniFolderNetwork,
  uniFolderOpen,
  uniFolderPlus,
  uniFolderQuestion,
  uniFolderSlash,
  uniFolderTimes,
  uniFolderUpload,
  uniFont,
  uniFootball,
  uniFootballAmerican,
  uniFootballBall,
  uniForecastcloudMoonTear,
  uniForwadedCall,
  uniForward,
  uniFrown,
  uniGameStructure,
  uniGift,
  uniGithub,
  uniGithubAlt,
  uniGitlab,
  uniGlass,
  uniGlassMartini,
  uniGlassMartiniAlt,
  uniGlassMartiniAltSlash,
  uniGlassTea,
  uniGlobe,
  uniGold,
  uniGolfBall,
  uniGoogle,
  uniGoogleDrive,
  uniGoogleDriveAlt,
  uniGoogleHangouts,
  uniGoogleHangoutsAlt,
  uniGooglePlay,
  uniGraduationCap,
  uniGraphBar,
  uniGrid,
  uniGrids,
  uniGrin,
  uniGrinTongueWink,
  uniGrinTongueWinkAlt,
  uniGripHorizontalLine,
  uniHardHat,
  uniHdd,
  uniHeadSide,
  uniHeadSideCough,
  uniHeadSideMask,
  uniHeadphoneSlash,
  uniHeadphones,
  uniHeadphonesAlt,
  uniHeart,
  uniHeartAlt,
  uniHeartBreak,
  uniHeartMedical,
  uniHeartRate,
  uniHeartSign,
  uniHeartbeat,
  uniHindiToChinese,
  uniHipchat,
  uniHistory,
  uniHistoryAlt,
  uniHome,
  uniHorizontalAlignCenter,
  uniHorizontalAlignLeft,
  uniHorizontalAlignRight,
  uniHorizontalDistributionCenter,
  uniHorizontalDistributionLeft,
  uniHorizontalDistributionRight,
  uniHospital,
  uniHospitalSquareSign,
  uniHospitalSymbol,
  uniHourglass,
  uniHouseUser,
  uniHtml3,
  uniHtml3Alt,
  uniHtml5,
  uniHtml5Alt,
  uniHunting,
  uniIcons,
  uniIllustration,
  uniImage,
  uniImageAltSlash,
  uniImageBlock,
  uniImageBroken,
  uniImageCheck,
  uniImageDownload,
  uniImageEdit,
  uniImageLock,
  uniImageMinus,
  uniImagePlus,
  uniImageQuestion,
  uniImageRedo,
  uniImageResizeLandscape,
  uniImageResizeSquare,
  uniImageSearch,
  uniImageShare,
  uniImageShield,
  uniImageSlash,
  uniImageTimes,
  uniImageUpload,
  uniImageV,
  uniImages,
  uniImport,
  uniInbox,
  uniIncomingCall,
  uniInfo,
  uniInfoCircle,
  uniInstagram,
  uniInstagramAlt,
  uniIntercom,
  uniIntercomAlt,
  uniInvoice,
  uniItalic,
  uniJackhammer,
  uniJavaScript,
  uniKayak,
  uniKeySkeleton,
  uniKeySkeletonAlt,
  uniKeyboard,
  uniKeyboardAlt,
  uniKeyboardHide,
  uniKeyboardShow,
  uniKeyholeCircle,
  uniKeyholeSquare,
  uniKeyholeSquareFull,
  uniKid,
  uniLabel,
  uniLabelAlt,
  uniLamp,
  uniLanguage,
  uniLaptop,
  uniLaptopCloud,
  uniLaptopConnection,
  uniLaughing,
  uniLayerGroup,
  uniLayerGroupSlash,
  uniLayers,
  uniLayersAlt,
  uniLayersSlash,
  uniLeftArrowFromLeft,
  uniLeftArrowToLeft,
  uniLeftIndent,
  uniLeftIndentAlt,
  uniLeftToRightTextDirection,
  uniLetterChineseA,
  uniLetterEnglishA,
  uniLetterHindiA,
  uniLetterJapaneseA,
  uniLifeRing,
  uniLightbulb,
  uniLightbulbAlt,
  uniLine,
  uniLineAlt,
  uniLineSpacing,
  uniLink,
  uniLinkAdd,
  uniLinkAlt,
  uniLinkBroken,
  uniLinkH,
  uniLinkedin,
  uniLinkedinAlt,
  uniLinux,
  uniLiraSign,
  uniListOl,
  uniListOlAlt,
  uniListUiAlt,
  uniListUl,
  uniLocationArrow,
  uniLocationArrowAlt,
  uniLocationPinAlt,
  uniLocationPoint,
  uniLock,
  uniLockAccess,
  uniLockAlt,
  uniLockOpenAlt,
  uniLockSlash,
  uniLottiefiles,
  uniLottiefilesAlt,
  uniLuggageCart,
  uniMailbox,
  uniMailboxAlt,
  uniMap,
  uniMapMarker,
  uniMapMarkerAlt,
  uniMapMarkerEdit,
  uniMapMarkerInfo,
  uniMapMarkerMinus,
  uniMapMarkerPlus,
  uniMapMarkerQuestion,
  uniMapMarkerShield,
  uniMapMarkerSlash,
  uniMapPin,
  uniMapPinAlt,
  uniMars,
  uniMasterCard,
  uniMaximizeLeft,
  uniMedal,
  uniMedicalDrip,
  uniMedicalSquare,
  uniMedicalSquareFull,
  uniMediumM,
  uniMedkit,
  uniMeetingBoard,
  uniMegaphone,
  uniMeh,
  uniMehAlt,
  uniMehClosedEye,
  uniMessage,
  uniMetro,
  uniMicrophone,
  uniMicrophoneSlash,
  uniMicroscope,
  uniMicrosoft,
  uniMinus,
  uniMinusCircle,
  uniMinusPath,
  uniMinusSquare,
  uniMinusSquareFull,
  uniMissedCall,
  uniMobileAndroid,
  uniMobileAndroidAlt,
  uniMobileVibrate,
  uniModem,
  uniMoneyBill,
  uniMoneyBillSlash,
  uniMoneyBillStack,
  uniMoneyInsert,
  uniMoneyStack,
  uniMoneyWithdraw,
  uniMoneyWithdrawal,
  uniMoneybag,
  uniMoneybagAlt,
  uniMonitor,
  uniMonitorHeartRate,
  uniMoon,
  uniMoonEclipse,
  uniMoonset,
  uniMountains,
  uniMountainsSun,
  uniMouse,
  uniMouseAlt,
  uniMouseAlt2,
  uniMultiply,
  uniMusic,
  uniMusicNote,
  uniMusicTuneSlash,
  uniNA,
  uniNavigator,
  uniNerd,
  uniNewspaper,
  uniNinja,
  uniNoEntry,
  uniNotebooks,
  uniNotes,
  uniObjectGroup,
  uniObjectUngroup,
  uniOctagon,
  uniOkta,
  uniOpera,
  uniOperaAlt,
  uniOutgoingCall,
  uniPackage,
  uniPadlock,
  uniPagelines,
  uniPagerduty,
  uniPaintTool,
  uniPalette,
  uniPanelAdd,
  uniPanoramaH,
  uniPanoramaHAlt,
  uniPanoramaV,
  uniPaperclip,
  uniParagraph,
  uniParcel,
  uniParkingCircle,
  uniParkingSquare,
  uniPathfinder,
  uniPathfinderUnite,
  uniPause,
  uniPauseCircle,
  uniPaypal,
  uniPen,
  uniPentagon,
  uniPercentage,
  uniPhone,
  uniPhoneAlt,
  uniPhonePause,
  uniPhoneSlash,
  uniPhoneTimes,
  uniPhoneVolume,
  uniPicture,
  uniPizzaSlice,
  uniPlane,
  uniPlaneArrival,
  uniPlaneDeparture,
  uniPlaneFly,
  uniPlay,
  uniPlayCircle,
  uniPlug,
  uniPlus,
  uniPlusCircle,
  uniPlusSquare,
  uniPodium,
  uniPolygon,
  uniPostStamp,
  uniPostcard,
  uniPound,
  uniPoundCircle,
  uniPower,
  uniPrescriptionBottle,
  uniPresentation,
  uniPresentationCheck,
  uniPresentationEdit,
  uniPresentationLine,
  uniPresentationLinesAlt,
  uniPresentationMinus,
  uniPresentationPlay,
  uniPresentationPlus,
  uniPresentationTimes,
  uniPrevious,
  uniPricetagAlt,
  uniPrint,
  uniPrintSlash,
  uniProcess,
  uniProcessor,
  uniProgrammingLanguage,
  uniPump,
  uniPuzzlePiece,
  uniQrcodeScan,
  uniQuestion,
  uniQuestionCircle,
  uniRainbow,
  uniRaindrops,
  uniRaindropsAlt,
  uniReact,
  uniReceipt,
  uniReceiptAlt,
  uniRecordAudio,
  uniRedditAlienAlt,
  uniRedo,
  uniRefresh,
  uniRegistered,
  uniRepeat,
  uniRestaurant,
  uniRightIndentAlt,
  uniRightToLeftTextDirection,
  uniRobot,
  uniRocket,
  uniRopeWay,
  uniRotate360,
  uniRss,
  uniRssAlt,
  uniRssInterface,
  uniRuler,
  uniRulerCombined,
  uniRupeeSign,
  uniSad,
  uniSadCry,
  uniSadCrying,
  uniSadDizzy,
  uniSadSquint,
  uniSanitizer,
  uniSanitizerAlt,
  uniSave,
  uniScalingLeft,
  uniScalingRight,
  uniScenery,
  uniSchedule,
  uniScrew,
  uniScroll,
  uniScrollH,
  uniSearch,
  uniSearchAlt,
  uniSearchMinus,
  uniSearchPlus,
  uniSelfie,
  uniServer,
  uniServerAlt,
  uniServerConnection,
  uniServerNetwork,
  uniServerNetworkAlt,
  uniServers,
  uniServicemark,
  uniSetting,
  uniShare,
  uniShareAlt,
  uniShield,
  uniShieldCheck,
  uniShieldExclamation,
  uniShieldPlus,
  uniShieldQuestion,
  uniShieldSlash,
  uniShip,
  uniShop,
  uniShoppingBag,
  uniShoppingBasket,
  uniShoppingCart,
  uniShoppingCartAlt,
  uniShovel,
  uniShrink,
  uniShuffle,
  uniShutter,
  uniShutterAlt,
  uniSick,
  uniSigma,
  uniSignAlt,
  uniSignInAlt,
  uniSignLeft,
  uniSignOutAlt,
  uniSignRight,
  uniSignal,
  uniSignalAlt,
  uniSignalAlt3,
  uniSignin,
  uniSignout,
  uniSilence,
  uniSilentSquint,
  uniSimCard,
  uniSitemap,
  uniSkipForward,
  uniSkipForwardAlt,
  uniSkipForwardCircle,
  uniSkype,
  uniSkypeAlt,
  uniSlack,
  uniSlackAlt,
  uniSliderH,
  uniSliderHRange,
  uniSlidersV,
  uniSlidersVAlt,
  uniSmile,
  uniSmileBeam,
  uniSmileDizzy,
  uniSmileSquintWink,
  uniSmileSquintWinkAlt,
  uniSmileWink,
  uniSmileWinkAlt,
  uniSnapchatAlt,
  uniSnapchatGhost,
  uniSnapchatSquare,
  uniSnowFlake,
  uniSnowflake,
  uniSnowflakeAlt,
  uniSocialDistancing,
  uniSort,
  uniSortAmountDown,
  uniSortAmountUp,
  uniSorting,
  uniSpaceKey,
  uniSpade,
  uniSperms,
  uniSpin,
  uniSpinner,
  uniSpinnerAlt,
  uniSquare,
  uniSquareFull,
  uniSquareShape,
  uniSquint,
  uniStar,
  uniStarHalfAlt,
  uniStepBackward,
  uniStepBackwardAlt,
  uniStepBackwardCircle,
  uniStepForward,
  uniStethoscope,
  uniStethoscopeAlt,
  uniStopCircle,
  uniStopwatch,
  uniStopwatchSlash,
  uniStore,
  uniStoreAlt,
  uniStoreSlash,
  uniStreering,
  uniStretcher,
  uniSubject,
  uniSubway,
  uniSubwayAlt,
  uniSuitcase,
  uniSuitcaseAlt,
  uniSun,
  uniSunset,
  uniSurprise,
  uniSwatchbook,
  uniSwiggy,
  uniSwimmer,
  uniSync,
  uniSyncExclamation,
  uniSyncSlash,
  uniSyringe,
  uniTable,
  uniTableTennis,
  uniTablet,
  uniTablets,
  uniTachometerFast,
  uniTachometerFastAlt,
  uniTag,
  uniTagAlt,
  uniTape,
  uniTaxi,
  uniTear,
  uniTelegram,
  uniTelegramAlt,
  uniTelescope,
  uniTemperature,
  uniTemperatureEmpty,
  uniTemperatureHalf,
  uniTemperatureMinus,
  uniTemperaturePlus,
  uniTemperatureQuarter,
  uniTemperatureThreeQuarter,
  uniTennisBall,
  uniText,
  uniTextFields,
  uniTextSize,
  uniTextStrikeThrough,
  uniTh,
  uniThLarge,
  uniThSlash,
  uniThermometer,
  uniThumbsDown,
  uniThumbsUp,
  uniThunderstorm,
  uniThunderstormMoon,
  uniThunderstormSun,
  uniTicket,
  uniTimes,
  uniTimesCircle,
  uniTimesSquare,
  uniToggleOff,
  uniToggleOn,
  uniToiletPaper,
  uniTopArrowFromTop,
  uniTopArrowToTop,
  uniTornado,
  uniTrademark,
  uniTrademarkCircle,
  uniTrafficBarrier,
  uniTrafficLight,
  uniTransaction,
  uniTrash,
  uniTrashAlt,
  uniTrees,
  uniTriangle,
  uniTrophy,
  uniTrowel,
  uniTruck,
  uniTruckLoading,
  uniTumblr,
  uniTumblrAlt,
  uniTumblrSquare,
  uniTvRetro,
  uniTvRetroSlash,
  uniTwitter,
  uniTwitterAlt,
  uniUmbrella,
  uniUnamused,
  uniUnderline,
  uniUniversity,
  uniUnlock,
  uniUnlockAlt,
  uniUpload,
  uniUploadAlt,
  uniUsdCircle,
  uniUsdSquare,
  uniUser,
  uniUserArrows,
  uniUserCheck,
  uniUserCircle,
  uniUserExclamation,
  uniUserLocation,
  uniUserMd,
  uniUserMinus,
  uniUserNurse,
  uniUserPlus,
  uniUserSquare,
  uniUserTimes,
  uniUsersAlt,
  uniUtensils,
  uniUtensilsAlt,
  uniVectorSquare,
  uniVectorSquareAlt,
  uniVenus,
  uniVerticalAlignBottom,
  uniVerticalAlignCenter,
  uniVerticalAlignTop,
  uniVerticalDistributeBottom,
  uniVerticalDistributionCenter,
  uniVerticalDistributionTop,
  uniVideo,
  uniVideoQuestion,
  uniVideoSlash,
  uniVirusSlash,
  uniVisualStudio,
  uniVk,
  uniVkAlt,
  uniVoicemail,
  uniVoicemailRectangle,
  uniVolleyball,
  uniVolume,
  uniVolumeDown,
  uniVolumeMute,
  uniVolumeOff,
  uniVolumeUp,
  uniVuejs,
  uniVuejsAlt,
  uniWall,
  uniWallet,
  uniWatch,
  uniWatchAlt,
  uniWater,
  uniWaterDropSlash,
  uniWaterGlass,
  uniWebGrid,
  uniWebGridAlt,
  uniWebSection,
  uniWebSectionAlt,
  uniWebcam,
  uniWeight,
  uniWhatsapp,
  uniWhatsappAlt,
  uniWheelBarrow,
  uniWheelchair,
  uniWheelchairAlt,
  uniWifi,
  uniWifiRouter,
  uniWifiSlash,
  uniWind,
  uniWindMoon,
  uniWindSun,
  uniWindow,
  uniWindowGrid,
  uniWindowMaximize,
  uniWindowSection,
  uniWindows,
  uniWindsock,
  uniWindy,
  uniWordpress,
  uniWordpressSimple,
  uniWrapText,
  uniWrench,
  uniX,
  uniXAdd,
  uniYen,
  uniYenCircle,
  uniYinYang,
  uniYoutube,
  uni500PxMonochrome,
  uniAdobeMonochrome,
  uniAdobeAltMonochrome,
  uniAirplayMonochrome,
  uniAlignMonochrome,
  uniAlignAltMonochrome,
  uniAlignCenterMonochrome,
  uniAlignCenterJustifyMonochrome,
  uniAlignJustifyMonochrome,
  uniAlignLeftMonochrome,
  uniAlignLeftJustifyMonochrome,
  uniAlignLetterRightMonochrome,
  uniAlignRightMonochrome,
  uniAlignRightJustifyMonochrome,
  uniAmazonMonochrome,
  uniAnalysisMonochrome,
  uniAnalyticsMonochrome,
  uniAnchorMonochrome,
  uniAndroidMonochrome,
  uniAndroidAltMonochrome,
  uniAngleDoubleDownMonochrome,
  uniAngleDoubleLeftMonochrome,
  uniAngleDoubleRightMonochrome,
  uniAngleDoubleUpMonochrome,
  uniAngleDownMonochrome,
  uniAngleLeftMonochrome,
  uniAngleRightMonochrome,
  uniAngleRightBMonochrome,
  uniAngleUpMonochrome,
  uniAppleMonochrome,
  uniAppleAltMonochrome,
  uniAppsMonochrome,
  uniArrowCircleDownMonochrome,
  uniArrowCircleLeftMonochrome,
  uniArrowCircleRightMonochrome,
  uniArrowCircleUpMonochrome,
  uniArrowDownLeftMonochrome,
  uniArrowDownRightMonochrome,
  uniArrowUpLeftMonochrome,
  uniArrowUpRightMonochrome,
  uniAtMonochrome,
  uniBagMonochrome,
  uniBarsMonochrome,
  uniBatteryBoltMonochrome,
  uniBatteryEmptyMonochrome,
  uniBehanceMonochrome,
  uniBehanceAltMonochrome,
  uniBingMonochrome,
  uniBitcoinMonochrome,
  uniBitcoinAltMonochrome,
  uniBlackberryMonochrome,
  uniBloggerMonochrome,
  uniBloggerAltMonochrome,
  uniBookmarkMonochrome,
  uniBorderAltMonochrome,
  uniBorderBottomMonochrome,
  uniBorderClearMonochrome,
  uniBorderHorizontalMonochrome,
  uniBorderInnerMonochrome,
  uniBorderLeftMonochrome,
  uniBorderOutMonochrome,
  uniBorderRightMonochrome,
  uniBorderTopMonochrome,
  uniBorderVerticalMonochrome,
  uniBoxMonochrome,
  uniBriefcaseMonochrome,
  uniCalenderMonochrome,
  uniChartMonochrome,
  uniChartPieMonochrome,
  uniCheckMonochrome,
  uniCheckCircleMonochrome,
  uniCheckSquareMonochrome,
  uniCircleMonochrome,
  uniCircleLayerMonochrome,
  uniClinicMedicalMonochrome,
  uniClockMonochrome,
  uniClockEightMonochrome,
  uniClockFiveMonochrome,
  uniClockNineMonochrome,
  uniClockSevenMonochrome,
  uniClockTenMonochrome,
  uniClockThreeMonochrome,
  uniClockTwoMonochrome,
  uniColumnsMonochrome,
  uniCommentMonochrome,
  uniCommentAltMonochrome,
  uniCommentAltDotsMonochrome,
  uniCommentAltMessageMonochrome,
  uniCommentAltPlusMonochrome,
  uniCommentDotsMonochrome,
  uniCommentMessageMonochrome,
  uniCommentPlusMonochrome,
  uniCompressMonochrome,
  uniCornerDownLeftMonochrome,
  uniCornerDownRightMonochrome,
  uniCornerLeftDownMonochrome,
  uniCornerRightDownMonochrome,
  uniCornerUpLeftMonochrome,
  uniCornerUpRightMonochrome,
  uniCoronavirusMonochrome,
  uniCss3Monochrome,
  uniCss3SimpleMonochrome,
  uniCubeMonochrome,
  uniDialpadMonochrome,
  uniDialpadAltMonochrome,
  uniDirectionMonochrome,
  uniDiscordMonochrome,
  uniDockerMonochrome,
  uniDocumentLayoutCenterMonochrome,
  uniDocumentLayoutLeftMonochrome,
  uniDocumentLayoutRightMonochrome,
  uniDownloadAltMonochrome,
  uniDribbbleMonochrome,
  uniDropboxMonochrome,
  uniEllipsisHMonochrome,
  uniEllipsisVMonochrome,
  uniExclamationCircleMonochrome,
  uniExclamationOctagonMonochrome,
  uniExclamationTriangleMonochrome,
  uniFacebookMonochrome,
  uniFacebookFMonochrome,
  uniFacebookMessengerMonochrome,
  uniFacebookMessengerAltMonochrome,
  uniFavoriteMonochrome,
  uniFlipHMonochrome,
  uniFlipHAltMonochrome,
  uniFlipVMonochrome,
  uniFlipVAltMonochrome,
  uniGithubMonochrome,
  uniGithubAltMonochrome,
  uniGitlabMonochrome,
  uniGitlabAltMonochrome,
  uniGoogleMonochrome,
  uniGoogleDriveMonochrome,
  uniGoogleDriveAltMonochrome,
  uniGoogleHangoutsMonochrome,
  uniGoogleHangoutsAltMonochrome,
  uniGooglePlayMonochrome,
  uniGraphBarMonochrome,
  uniGridMonochrome,
  uniGridsMonochrome,
  uniGripHorizontalLineMonochrome,
  uniHeadSideMonochrome,
  uniHeadSideCoughMonochrome,
  uniHeadSideMaskMonochrome,
  uniHipchatMonochrome,
  uniHistoryMonochrome,
  uniHistoryAltMonochrome,
  uniHorizontalAlignLeftMonochrome,
  uniHospitalMonochrome,
  uniHospitalSquareSignMonochrome,
  uniHospitalSymbolMonochrome,
  uniHouseUserMonochrome,
  uniHtml3Monochrome,
  uniHtml3AltMonochrome,
  uniHtml5Monochrome,
  uniHtml5AltMonochrome,
  uniImageVMonochrome,
  uniInstagramMonochrome,
  uniInstagramAltMonochrome,
  uniIntercomMonochrome,
  uniIntercomAltMonochrome,
  uniJavaScriptMonochrome,
  uniKeySkeletonMonochrome,
  uniKeySkeletonAltMonochrome,
  uniKeyholeCircleMonochrome,
  uniKeyholeSquareMonochrome,
  uniKeyholeSquareFullMonochrome,
  uniLayerGroupMonochrome,
  uniLayersAltMonochrome,
  uniLeftIndentMonochrome,
  uniLeftIndentAltMonochrome,
  uniLineMonochrome,
  uniLineSpacingMonochrome,
  uniLinkHMonochrome,
  uniLinkedinMonochrome,
  uniLinkedinAltMonochrome,
  uniLinuxMonochrome,
  uniListUiAltMonochrome,
  uniListUlMonochrome,
  uniLockMonochrome,
  uniLockAccessMonochrome,
  uniLockAltMonochrome,
  uniLockOpenAltMonochrome,
  uniLottiefilesMonochrome,
  uniMasterCardMonochrome,
  uniMediumMMonochrome,
  uniMicroscopeMonochrome,
  uniMicrosoftMonochrome,
  uniMinusSquareFullMonochrome,
  uniMultiplyMonochrome,
  uniObjectGroupMonochrome,
  uniObjectUngroupMonochrome,
  uniOktaMonochrome,
  uniOperaMonochrome,
  uniOperaAltMonochrome,
  uniPadlockMonochrome,
  uniPagelinesMonochrome,
  uniPagerdutyMonochrome,
  uniPaperclipMonochrome,
  uniParagraphMonochrome,
  uniPaypalMonochrome,
  uniPentagonMonochrome,
  uniPlusSquareMonochrome,
  uniPolygonMonochrome,
  uniPreviousMonochrome,
  uniProcessMonochrome,
  uniReactMonochrome,
  uniRecordAudioMonochrome,
  uniRedditAlienAltMonochrome,
  uniRedoMonochrome,
  uniRefreshMonochrome,
  uniRepeatMonochrome,
  uniRightIndentAltMonochrome,
  uniRocketMonochrome,
  uniRulerMonochrome,
  uniRulerCombinedMonochrome,
  uniSanitizerMonochrome,
  uniSanitizerAltMonochrome,
  uniSceneryMonochrome,
  uniScheduleMonochrome,
  uniShieldPlusMonochrome,
  uniSignInAltMonochrome,
  uniSignOutAltMonochrome,
  uniSignalAltMonochrome,
  uniSignalAlt3Monochrome,
  uniSigninMonochrome,
  uniSignoutMonochrome,
  uniSkypeMonochrome,
  uniSkypeAltMonochrome,
  uniSlackMonochrome,
  uniSlackAltMonochrome,
  uniSnapchatAltMonochrome,
  uniSnapchatGhostMonochrome,
  uniSnapchatSquareMonochrome,
  uniSocialDistancingMonochrome,
  uniSortingMonochrome,
  uniSpaceKeyMonochrome,
  uniSquareMonochrome,
  uniSquareFullMonochrome,
  uniSqureShapeMonochrome,
  uniStarMonochrome,
  uniStarHalfAltMonochrome,
  uniStepForwardMonochrome,
  uniStethoscopeMonochrome,
  uniStethoscopeAltMonochrome,
  uniStoreSlashMonochrome,
  uniSubjectMonochrome,
  uniSwiggyMonochrome,
  uniSyncExclamationMonochrome,
  uniSyncSlashMonochrome,
  uniTableMonochrome,
  uniTelegramMonochrome,
  uniTelegramAltMonochrome,
  uniThLargeMonochrome,
  uniTimesCircleMonochrome,
  uniToggleOffMonochrome,
  uniToggleOnMonochrome,
  uniToiletPaperMonochrome,
  uniTriangleMonochrome,
  uniTumblrMonochrome,
  uniTumblrAltMonochrome,
  uniTumblrSquareMonochrome,
  uniTwitterMonochrome,
  uniTwitterAltMonochrome,
  uniUnlockMonochrome,
  uniUnlockAltMonochrome,
  uniUploadAltMonochrome,
  uniUserArrowsMonochrome,
  uniUserMdMonochrome,
  uniUserNurseMonochrome,
  uniVectorSquareMonochrome,
  uniVectorSquareAltMonochrome,
  uniVirusSlashMonochrome,
  uniVisualStudioMonochrome,
  uniVkMonochrome,
  uniVkAltMonochrome,
  uniVuejsMonochrome,
  uniVuejsAltMonochrome,
  uniWebGridMonochrome,
  uniWebGridAltMonochrome,
  uniWebSectionMonochrome,
  uniWebSectionAltMonochrome,
  uniWhatsappMonochrome,
  uniWindowGridMonochrome,
  uniWindowMaximizeMonochrome,
  uniWindowSectionMonochrome,
  uniWindowsMonochrome,
  uniWordpressMonochrome,
  uniWordpressSimpleMonochrome,
  uniWrapTextMonochrome,
  uniYoutubeMonochrome,
} from 'vue-unicons/dist/icons'

Unicon.add([
  uni0Plus,
  uni10Plus,
  uni12Plus,
  uni13Plus,
  uni16Plus,
  uni17Plus,
  uni18Plus,
  uni21Plus,
  uni3Plus,
  uni500Px,
  uni6Plus,
  uniAbacus,
  uniAccessibleIconAlt,
  uniAdjust,
  uniAdjustAlt,
  uniAdjustCircle,
  uniAdjustHalf,
  uniAdobe,
  uniAdobeAlt,
  uniAirplay,
  uniAlign,
  uniAlignAlt,
  uniAlignCenter,
  uniAlignCenterAlt,
  uniAlignCenterH,
  uniAlignCenterJustify,
  uniAlignCenterV,
  uniAlignJustify,
  uniAlignLeft,
  uniAlignLeftJustify,
  uniAlignLetterRight,
  uniAlignRight,
  uniAlignRightJustify,
  uniAmazon,
  uniAmbulance,
  uniAnalysis,
  uniAnalytics,
  uniAnchor,
  uniAndroid,
  uniAndroidAlt,
  uniAndroidPhoneSlash,
  uniAngleDoubleDown,
  uniAngleDoubleLeft,
  uniAngleDoubleRight,
  uniAngleDoubleUp,
  uniAngleDown,
  uniAngleLeft,
  uniAngleLeftB,
  uniAngleRight,
  uniAngleRightB,
  uniAngleUp,
  uniAngry,
  uniAnkh,
  uniAnnoyed,
  uniAnnoyedAlt,
  uniApple,
  uniAppleAlt,
  uniApps,
  uniArchive,
  uniArchiveAlt,
  uniArchway,
  uniArrow,
  uniArrowBreak,
  uniArrowCircleDown,
  uniArrowCircleLeft,
  uniArrowCircleRight,
  uniArrowCircleUp,
  uniArrowCompressH,
  uniArrowDown,
  uniArrowDownLeft,
  uniArrowDownRight,
  uniArrowFromRight,
  uniArrowFromTop,
  uniArrowGrowth,
  uniArrowLeft,
  uniArrowRandom,
  uniArrowResizeDiagonal,
  uniArrowRight,
  uniArrowToBottom,
  uniArrowToRight,
  uniArrowUp,
  uniArrowUpLeft,
  uniArrowUpRight,
  uniArrowsH,
  uniArrowsHAlt,
  uniArrowsLeftDown,
  uniArrowsMaximize,
  uniArrowsMerge,
  uniArrowsResize,
  uniArrowsResizeH,
  uniArrowsResizeV,
  uniArrowsRightDown,
  uniArrowsShrinkH,
  uniArrowsShrinkV,
  uniArrowsUpRight,
  uniArrowsV,
  uniArrowsVAlt,
  uniAssistiveListeningSystems,
  uniAsterisk,
  uniAt,
  uniAtom,
  uniAutoFlash,
  uniAward,
  uniAwardAlt,
  uniBabyCarriage,
  uniBackpack,
  uniBackspace,
  uniBackward,
  uniBag,
  uniBagAlt,
  uniBagSlash,
  uniBalanceScale,
  uniBan,
  uniBandAid,
  uniBars,
  uniBaseballBall,
  uniBasketball,
  uniBasketballHoop,
  uniBath,
  uniBatteryBolt,
  uniBatteryEmpty,
  uniBed,
  uniBedDouble,
  uniBehance,
  uniBehanceAlt,
  uniBell,
  uniBellSchool,
  uniBellSlash,
  uniBill,
  uniBing,
  uniBitcoin,
  uniBitcoinAlt,
  uniBitcoinCircle,
  uniBitcoinSign,
  uniBlackBerry,
  uniBlogger,
  uniBloggerAlt,
  uniBluetoothB,
  uniBold,
  uniBolt,
  uniBoltAlt,
  uniBoltSlash,
  uniBook,
  uniBookAlt,
  uniBookMedical,
  uniBookOpen,
  uniBookReader,
  uniBookmark,
  uniBookmarkFull,
  uniBooks,
  uniBoombox,
  uniBorderAlt,
  uniBorderBottom,
  uniBorderClear,
  uniBorderHorizontal,
  uniBorderInner,
  uniBorderLeft,
  uniBorderOut,
  uniBorderRight,
  uniBorderTop,
  uniBorderVertical,
  uniBowlingBall,
  uniBox,
  uniBracketsCurly,
  uniBrain,
  uniBriefcase,
  uniBriefcaseAlt,
  uniBright,
  uniBrightness,
  uniBrightnessEmpty,
  uniBrightnessHalf,
  uniBrightnessLow,
  uniBrightnessMinus,
  uniBrightnessPlus,
  uniBringBottom,
  uniBringFront,
  uniBrowser,
  uniBrushAlt,
  uniBug,
  uniBuilding,
  uniBullseye,
  uniBus,
  uniBusAlt,
  uniBusSchool,
  uniCalculator,
  uniCalculatorAlt,
  uniCalendarAlt,
  uniCalendarSlash,
  uniCalender,
  uniCalling,
  uniCamera,
  uniCameraChange,
  uniCameraPlus,
  uniCameraSlash,
  uniCancel,
  uniCapsule,
  uniCapture,
  uniCar,
  uniCarSideview,
  uniCarSlash,
  uniCarWash,
  uniCardAtm,
  uniCaretRight,
  uniCell,
  uniCelsius,
  uniChannel,
  uniChannelAdd,
  uniChart,
  uniChartBar,
  uniChartBarAlt,
  uniChartDown,
  uniChartGrowth,
  uniChartGrowthAlt,
  uniChartLine,
  uniChartPie,
  uniChartPieAlt,
  uniChat,
  uniChatBubbleUser,
  uniChatInfo,
  uniCheck,
  uniCheckCircle,
  uniCheckSquare,
  uniCircle,
  uniCircleLayer,
  uniCircuit,
  uniClapperBoard,
  uniClinicMedical,
  uniClipboard,
  uniClipboardAlt,
  uniClipboardBlank,
  uniClipboardNotes,
  uniClock,
  uniClockEight,
  uniClockFive,
  uniClockNine,
  uniClockSeven,
  uniClockTen,
  uniClockThree,
  uniClockTwo,
  uniClosedCaptioning,
  uniClosedCaptioningSlash,
  uniCloud,
  uniCloudBlock,
  uniCloudBookmark,
  uniCloudCheck,
  uniCloudComputing,
  uniCloudDataConnection,
  uniCloudDatabaseTree,
  uniCloudDownload,
  uniCloudDrizzle,
  uniCloudExclamation,
  uniCloudHail,
  uniCloudHeart,
  uniCloudInfo,
  uniCloudLock,
  uniCloudMeatball,
  uniCloudMoon,
  uniCloudMoonHail,
  uniCloudMoonMeatball,
  uniCloudMoonRain,
  uniCloudMoonShowers,
  uniCloudQuestion,
  uniCloudRain,
  uniCloudRainSun,
  uniCloudRedo,
  uniCloudShare,
  uniCloudShield,
  uniCloudShowers,
  uniCloudShowersAlt,
  uniCloudShowersHeavy,
  uniCloudSlash,
  uniCloudSun,
  uniCloudSunHail,
  uniCloudSunMeatball,
  uniCloudSunRain,
  uniCloudSunRainAlt,
  uniCloudSunTear,
  uniCloudTimes,
  uniCloudUnlock,
  uniCloudUpload,
  uniCloudWifi,
  uniCloudWind,
  uniClouds,
  uniClub,
  uniCodeBranch,
  uniCoffee,
  uniCog,
  uniCoins,
  uniColumns,
  uniComment,
  uniCommentAdd,
  uniCommentAlt,
  uniCommentAltBlock,
  uniCommentAltChartLines,
  uniCommentAltCheck,
  uniCommentAltDots,
  uniCommentAltDownload,
  uniCommentAltEdit,
  uniCommentAltExclamation,
  uniCommentAltHeart,
  uniCommentAltImage,
  uniCommentAltInfo,
  uniCommentAltLines,
  uniCommentAltLock,
  uniCommentAltMedical,
  uniCommentAltMessage,
  uniCommentAltNotes,
  uniCommentAltPlus,
  uniCommentAltQuestion,
  uniCommentAltRedo,
  uniCommentAltSearch,
  uniCommentAltShare,
  uniCommentAltShield,
  uniCommentAltSlash,
  uniCommentAltUpload,
  uniCommentAltVerify,
  uniCommentBlock,
  uniCommentChartLine,
  uniCommentCheck,
  uniCommentDots,
  uniCommentDownload,
  uniCommentEdit,
  uniCommentExclamation,
  uniCommentHeart,
  uniCommentImage,
  uniCommentInfo,
  uniCommentInfoAlt,
  uniCommentLines,
  uniCommentLock,
  uniCommentMedical,
  uniCommentMessage,
  uniCommentNotes,
  uniCommentPlus,
  uniCommentQuestion,
  uniCommentRedo,
  uniCommentSearch,
  uniCommentShare,
  uniCommentShield,
  uniCommentSlash,
  uniCommentUpload,
  uniCommentVerify,
  uniComments,
  uniCommentsAlt,
  uniCompactDisc,
  uniComparison,
  uniCompass,
  uniCompress,
  uniCompressAlt,
  uniCompressAltLeft,
  uniCompressArrows,
  uniCompressLines,
  uniCompressPoint,
  uniCompressV,
  uniConfused,
  uniConstructor,
  uniCopy,
  uniCopyAlt,
  uniCopyLandscape,
  uniCopyright,
  uniCornerDownLeft,
  uniCornerDownRight,
  uniCornerDownRightAlt,
  uniCornerLeftDown,
  uniCornerRightDown,
  uniCornerUpLeft,
  uniCornerUpLeftAlt,
  uniCornerUpRight,
  uniCornerUpRightAlt,
  uniCoronavirus,
  uniCreateDashboard,
  uniCreativeCommonsPd,
  uniCreditCard,
  uniCreditCardSearch,
  uniCrockery,
  uniCropAlt,
  uniCropAltRotateLeft,
  uniCropAltRotateRight,
  uniCrosshair,
  uniCrosshairAlt,
  uniCrosshairs,
  uniCss3Simple,
  uniCube,
  uniDashboard,
  uniDataSharing,
  uniDatabase,
  uniDatabaseAlt,
  uniDesert,
  uniDesktop,
  uniDesktopAlt,
  uniDesktopAltSlash,
  uniDesktopCloudAlt,
  uniDesktopSlash,
  uniDialpad,
  uniDialpadAlt,
  uniDiamond,
  uniDiary,
  uniDiaryAlt,
  uniDiceFive,
  uniDiceFour,
  uniDiceOne,
  uniDiceSix,
  uniDiceThree,
  uniDiceTwo,
  uniDirection,
  uniDirections,
  uniDiscord,
  uniDizzyMeh,
  uniDna,
  uniDocker,
  uniDocumentInfo,
  uniDocumentLayoutCenter,
  uniDocumentLayoutLeft,
  uniDocumentLayoutRight,
  uniDollarAlt,
  uniDollarSign,
  uniDollarSignAlt,
  uniDownloadAlt,
  uniDraggabledots,
  uniDribbble,
  uniDrill,
  uniDropbox,
  uniDumbbell,
  uniEar,
  uniEdit,
  uniEditAlt,
  uniElipsisDoubleVAlt,
  uniEllipsisH,
  uniEllipsisV,
  uniEmoji,
  uniEnglishToChinese,
  uniEnter,
  uniEnvelope,
  uniEnvelopeAdd,
  uniEnvelopeAlt,
  uniEnvelopeBlock,
  uniEnvelopeBookmark,
  uniEnvelopeCheck,
  uniEnvelopeDownload,
  uniEnvelopeDownloadAlt,
  uniEnvelopeEdit,
  uniEnvelopeExclamation,
  uniEnvelopeHeart,
  uniEnvelopeInfo,
  uniEnvelopeLock,
  uniEnvelopeMinus,
  uniEnvelopeOpen,
  uniEnvelopeQuestion,
  uniEnvelopeReceive,
  uniEnvelopeRedo,
  uniEnvelopeSearch,
  uniEnvelopeSend,
  uniEnvelopeShare,
  uniEnvelopeShield,
  uniEnvelopeStar,
  uniEnvelopeTimes,
  uniEnvelopeUpload,
  uniEnvelopeUploadAlt,
  uniEnvelopes,
  uniEqualCircle,
  uniEstate,
  uniEuro,
  uniEuroCircle,
  uniExchange,
  uniExchangeAlt,
  uniExclamation,
  uniExclamationCircle,
  uniExclamationOctagon,
  uniExclamationTriangle,
  uniExclude,
  uniExpandAlt,
  uniExpandArrows,
  uniExpandArrowsAlt,
  uniExpandFromCorner,
  uniExpandLeft,
  uniExpandRight,
  uniExport,
  uniExposureAlt,
  uniExposureIncrease,
  uniExternalLinkAlt,
  uniEye,
  uniEyeSlash,
  uniFacebook,
  uniFacebookF,
  uniFacebookMessenger,
  uniFacebookMessengerAlt,
  uniFahrenheit,
  uniFastMail,
  uniFastMailAlt,
  uniFavorite,
  uniFeedback,
  uniFidgetSpinner,
  uniFile,
  uniFileAlt,
  uniFileBlank,
  uniFileBlockAlt,
  uniFileBookmarkAlt,
  uniFileCheck,
  uniFileCheckAlt,
  uniFileContract,
  uniFileContractDollar,
  uniFileCopyAlt,
  uniFileDownload,
  uniFileDownloadAlt,
  uniFileEditAlt,
  uniFileExclamation,
  uniFileExclamationAlt,
  uniFileExport,
  uniFileGraph,
  uniFileHeart,
  uniFileImport,
  uniFileInfoAlt,
  uniFileLandscape,
  uniFileLandscapeAlt,
  uniFileLanscapeSlash,
  uniFileLockAlt,
  uniFileMedical,
  uniFileMedicalAlt,
  uniFileMinus,
  uniFileMinusAlt,
  uniFileNetwork,
  uniFilePlus,
  uniFilePlusAlt,
  uniFileQuestion,
  uniFileQuestionAlt,
  uniFileRedoAlt,
  uniFileSearchAlt,
  uniFileShareAlt,
  uniFileShieldAlt,
  uniFileSlash,
  uniFileTimes,
  uniFileTimesAlt,
  uniFileUpload,
  uniFileUploadAlt,
  uniFilesLandscapes,
  uniFilesLandscapesAlt,
  uniFilm,
  uniFilter,
  uniFilterSlash,
  uniFire,
  uniFlask,
  uniFlaskPotion,
  uniFlipH,
  uniFlipHAlt,
  uniFlipV,
  uniFlipVAlt,
  uniFlower,
  uniFocus,
  uniFocusAdd,
  uniFocusTarget,
  uniFolder,
  uniFolderCheck,
  uniFolderDownload,
  uniFolderExclamation,
  uniFolderHeart,
  uniFolderInfo,
  uniFolderLock,
  uniFolderMedical,
  uniFolderMinus,
  uniFolderNetwork,
  uniFolderOpen,
  uniFolderPlus,
  uniFolderQuestion,
  uniFolderSlash,
  uniFolderTimes,
  uniFolderUpload,
  uniFont,
  uniFootball,
  uniFootballAmerican,
  uniFootballBall,
  uniForecastcloudMoonTear,
  uniForwadedCall,
  uniForward,
  uniFrown,
  uniGameStructure,
  uniGift,
  uniGithub,
  uniGithubAlt,
  uniGitlab,
  uniGlass,
  uniGlassMartini,
  uniGlassMartiniAlt,
  uniGlassMartiniAltSlash,
  uniGlassTea,
  uniGlobe,
  uniGold,
  uniGolfBall,
  uniGoogle,
  uniGoogleDrive,
  uniGoogleDriveAlt,
  uniGoogleHangouts,
  uniGoogleHangoutsAlt,
  uniGooglePlay,
  uniGraduationCap,
  uniGraphBar,
  uniGrid,
  uniGrids,
  uniGrin,
  uniGrinTongueWink,
  uniGrinTongueWinkAlt,
  uniGripHorizontalLine,
  uniHardHat,
  uniHdd,
  uniHeadSide,
  uniHeadSideCough,
  uniHeadSideMask,
  uniHeadphoneSlash,
  uniHeadphones,
  uniHeadphonesAlt,
  uniHeart,
  uniHeartAlt,
  uniHeartBreak,
  uniHeartMedical,
  uniHeartRate,
  uniHeartSign,
  uniHeartbeat,
  uniHindiToChinese,
  uniHipchat,
  uniHistory,
  uniHistoryAlt,
  uniHome,
  uniHorizontalAlignCenter,
  uniHorizontalAlignLeft,
  uniHorizontalAlignRight,
  uniHorizontalDistributionCenter,
  uniHorizontalDistributionLeft,
  uniHorizontalDistributionRight,
  uniHospital,
  uniHospitalSquareSign,
  uniHospitalSymbol,
  uniHourglass,
  uniHouseUser,
  uniHtml3,
  uniHtml3Alt,
  uniHtml5,
  uniHtml5Alt,
  uniHunting,
  uniIcons,
  uniIllustration,
  uniImage,
  uniImageAltSlash,
  uniImageBlock,
  uniImageBroken,
  uniImageCheck,
  uniImageDownload,
  uniImageEdit,
  uniImageLock,
  uniImageMinus,
  uniImagePlus,
  uniImageQuestion,
  uniImageRedo,
  uniImageResizeLandscape,
  uniImageResizeSquare,
  uniImageSearch,
  uniImageShare,
  uniImageShield,
  uniImageSlash,
  uniImageTimes,
  uniImageUpload,
  uniImageV,
  uniImages,
  uniImport,
  uniInbox,
  uniIncomingCall,
  uniInfo,
  uniInfoCircle,
  uniInstagram,
  uniInstagramAlt,
  uniIntercom,
  uniIntercomAlt,
  uniInvoice,
  uniItalic,
  uniJackhammer,
  uniJavaScript,
  uniKayak,
  uniKeySkeleton,
  uniKeySkeletonAlt,
  uniKeyboard,
  uniKeyboardAlt,
  uniKeyboardHide,
  uniKeyboardShow,
  uniKeyholeCircle,
  uniKeyholeSquare,
  uniKeyholeSquareFull,
  uniKid,
  uniLabel,
  uniLabelAlt,
  uniLamp,
  uniLanguage,
  uniLaptop,
  uniLaptopCloud,
  uniLaptopConnection,
  uniLaughing,
  uniLayerGroup,
  uniLayerGroupSlash,
  uniLayers,
  uniLayersAlt,
  uniLayersSlash,
  uniLeftArrowFromLeft,
  uniLeftArrowToLeft,
  uniLeftIndent,
  uniLeftIndentAlt,
  uniLeftToRightTextDirection,
  uniLetterChineseA,
  uniLetterEnglishA,
  uniLetterHindiA,
  uniLetterJapaneseA,
  uniLifeRing,
  uniLightbulb,
  uniLightbulbAlt,
  uniLine,
  uniLineAlt,
  uniLineSpacing,
  uniLink,
  uniLinkAdd,
  uniLinkAlt,
  uniLinkBroken,
  uniLinkH,
  uniLinkedin,
  uniLinkedinAlt,
  uniLinux,
  uniLiraSign,
  uniListOl,
  uniListOlAlt,
  uniListUiAlt,
  uniListUl,
  uniLocationArrow,
  uniLocationArrowAlt,
  uniLocationPinAlt,
  uniLocationPoint,
  uniLock,
  uniLockAccess,
  uniLockAlt,
  uniLockOpenAlt,
  uniLockSlash,
  uniLottiefiles,
  uniLottiefilesAlt,
  uniLuggageCart,
  uniMailbox,
  uniMailboxAlt,
  uniMap,
  uniMapMarker,
  uniMapMarkerAlt,
  uniMapMarkerEdit,
  uniMapMarkerInfo,
  uniMapMarkerMinus,
  uniMapMarkerPlus,
  uniMapMarkerQuestion,
  uniMapMarkerShield,
  uniMapMarkerSlash,
  uniMapPin,
  uniMapPinAlt,
  uniMars,
  uniMasterCard,
  uniMaximizeLeft,
  uniMedal,
  uniMedicalDrip,
  uniMedicalSquare,
  uniMedicalSquareFull,
  uniMediumM,
  uniMedkit,
  uniMeetingBoard,
  uniMegaphone,
  uniMeh,
  uniMehAlt,
  uniMehClosedEye,
  uniMessage,
  uniMetro,
  uniMicrophone,
  uniMicrophoneSlash,
  uniMicroscope,
  uniMicrosoft,
  uniMinus,
  uniMinusCircle,
  uniMinusPath,
  uniMinusSquare,
  uniMinusSquareFull,
  uniMissedCall,
  uniMobileAndroid,
  uniMobileAndroidAlt,
  uniMobileVibrate,
  uniModem,
  uniMoneyBill,
  uniMoneyBillSlash,
  uniMoneyBillStack,
  uniMoneyInsert,
  uniMoneyStack,
  uniMoneyWithdraw,
  uniMoneyWithdrawal,
  uniMoneybag,
  uniMoneybagAlt,
  uniMonitor,
  uniMonitorHeartRate,
  uniMoon,
  uniMoonEclipse,
  uniMoonset,
  uniMountains,
  uniMountainsSun,
  uniMouse,
  uniMouseAlt,
  uniMouseAlt2,
  uniMultiply,
  uniMusic,
  uniMusicNote,
  uniMusicTuneSlash,
  uniNA,
  uniNavigator,
  uniNerd,
  uniNewspaper,
  uniNinja,
  uniNoEntry,
  uniNotebooks,
  uniNotes,
  uniObjectGroup,
  uniObjectUngroup,
  uniOctagon,
  uniOkta,
  uniOpera,
  uniOperaAlt,
  uniOutgoingCall,
  uniPackage,
  uniPadlock,
  uniPagelines,
  uniPagerduty,
  uniPaintTool,
  uniPalette,
  uniPanelAdd,
  uniPanoramaH,
  uniPanoramaHAlt,
  uniPanoramaV,
  uniPaperclip,
  uniParagraph,
  uniParcel,
  uniParkingCircle,
  uniParkingSquare,
  uniPathfinder,
  uniPathfinderUnite,
  uniPause,
  uniPauseCircle,
  uniPaypal,
  uniPen,
  uniPentagon,
  uniPercentage,
  uniPhone,
  uniPhoneAlt,
  uniPhonePause,
  uniPhoneSlash,
  uniPhoneTimes,
  uniPhoneVolume,
  uniPicture,
  uniPizzaSlice,
  uniPlane,
  uniPlaneArrival,
  uniPlaneDeparture,
  uniPlaneFly,
  uniPlay,
  uniPlayCircle,
  uniPlug,
  uniPlus,
  uniPlusCircle,
  uniPlusSquare,
  uniPodium,
  uniPolygon,
  uniPostStamp,
  uniPostcard,
  uniPound,
  uniPoundCircle,
  uniPower,
  uniPrescriptionBottle,
  uniPresentation,
  uniPresentationCheck,
  uniPresentationEdit,
  uniPresentationLine,
  uniPresentationLinesAlt,
  uniPresentationMinus,
  uniPresentationPlay,
  uniPresentationPlus,
  uniPresentationTimes,
  uniPrevious,
  uniPricetagAlt,
  uniPrint,
  uniPrintSlash,
  uniProcess,
  uniProcessor,
  uniProgrammingLanguage,
  uniPump,
  uniPuzzlePiece,
  uniQrcodeScan,
  uniQuestion,
  uniQuestionCircle,
  uniRainbow,
  uniRaindrops,
  uniRaindropsAlt,
  uniReact,
  uniReceipt,
  uniReceiptAlt,
  uniRecordAudio,
  uniRedditAlienAlt,
  uniRedo,
  uniRefresh,
  uniRegistered,
  uniRepeat,
  uniRestaurant,
  uniRightIndentAlt,
  uniRightToLeftTextDirection,
  uniRobot,
  uniRocket,
  uniRopeWay,
  uniRotate360,
  uniRss,
  uniRssAlt,
  uniRssInterface,
  uniRuler,
  uniRulerCombined,
  uniRupeeSign,
  uniSad,
  uniSadCry,
  uniSadCrying,
  uniSadDizzy,
  uniSadSquint,
  uniSanitizer,
  uniSanitizerAlt,
  uniSave,
  uniScalingLeft,
  uniScalingRight,
  uniScenery,
  uniSchedule,
  uniScrew,
  uniScroll,
  uniScrollH,
  uniSearch,
  uniSearchAlt,
  uniSearchMinus,
  uniSearchPlus,
  uniSelfie,
  uniServer,
  uniServerAlt,
  uniServerConnection,
  uniServerNetwork,
  uniServerNetworkAlt,
  uniServers,
  uniServicemark,
  uniSetting,
  uniShare,
  uniShareAlt,
  uniShield,
  uniShieldCheck,
  uniShieldExclamation,
  uniShieldPlus,
  uniShieldQuestion,
  uniShieldSlash,
  uniShip,
  uniShop,
  uniShoppingBag,
  uniShoppingBasket,
  uniShoppingCart,
  uniShoppingCartAlt,
  uniShovel,
  uniShrink,
  uniShuffle,
  uniShutter,
  uniShutterAlt,
  uniSick,
  uniSigma,
  uniSignAlt,
  uniSignInAlt,
  uniSignLeft,
  uniSignOutAlt,
  uniSignRight,
  uniSignal,
  uniSignalAlt,
  uniSignalAlt3,
  uniSignin,
  uniSignout,
  uniSilence,
  uniSilentSquint,
  uniSimCard,
  uniSitemap,
  uniSkipForward,
  uniSkipForwardAlt,
  uniSkipForwardCircle,
  uniSkype,
  uniSkypeAlt,
  uniSlack,
  uniSlackAlt,
  uniSliderH,
  uniSliderHRange,
  uniSlidersV,
  uniSlidersVAlt,
  uniSmile,
  uniSmileBeam,
  uniSmileDizzy,
  uniSmileSquintWink,
  uniSmileSquintWinkAlt,
  uniSmileWink,
  uniSmileWinkAlt,
  uniSnapchatAlt,
  uniSnapchatGhost,
  uniSnapchatSquare,
  uniSnowFlake,
  uniSnowflake,
  uniSnowflakeAlt,
  uniSocialDistancing,
  uniSort,
  uniSortAmountDown,
  uniSortAmountUp,
  uniSorting,
  uniSpaceKey,
  uniSpade,
  uniSperms,
  uniSpin,
  uniSpinner,
  uniSpinnerAlt,
  uniSquare,
  uniSquareFull,
  uniSquareShape,
  uniSquint,
  uniStar,
  uniStarHalfAlt,
  uniStepBackward,
  uniStepBackwardAlt,
  uniStepBackwardCircle,
  uniStepForward,
  uniStethoscope,
  uniStethoscopeAlt,
  uniStopCircle,
  uniStopwatch,
  uniStopwatchSlash,
  uniStore,
  uniStoreAlt,
  uniStoreSlash,
  uniStreering,
  uniStretcher,
  uniSubject,
  uniSubway,
  uniSubwayAlt,
  uniSuitcase,
  uniSuitcaseAlt,
  uniSun,
  uniSunset,
  uniSurprise,
  uniSwatchbook,
  uniSwiggy,
  uniSwimmer,
  uniSync,
  uniSyncExclamation,
  uniSyncSlash,
  uniSyringe,
  uniTable,
  uniTableTennis,
  uniTablet,
  uniTablets,
  uniTachometerFast,
  uniTachometerFastAlt,
  uniTag,
  uniTagAlt,
  uniTape,
  uniTaxi,
  uniTear,
  uniTelegram,
  uniTelegramAlt,
  uniTelescope,
  uniTemperature,
  uniTemperatureEmpty,
  uniTemperatureHalf,
  uniTemperatureMinus,
  uniTemperaturePlus,
  uniTemperatureQuarter,
  uniTemperatureThreeQuarter,
  uniTennisBall,
  uniText,
  uniTextFields,
  uniTextSize,
  uniTextStrikeThrough,
  uniTh,
  uniThLarge,
  uniThSlash,
  uniThermometer,
  uniThumbsDown,
  uniThumbsUp,
  uniThunderstorm,
  uniThunderstormMoon,
  uniThunderstormSun,
  uniTicket,
  uniTimes,
  uniTimesCircle,
  uniTimesSquare,
  uniToggleOff,
  uniToggleOn,
  uniToiletPaper,
  uniTopArrowFromTop,
  uniTopArrowToTop,
  uniTornado,
  uniTrademark,
  uniTrademarkCircle,
  uniTrafficBarrier,
  uniTrafficLight,
  uniTransaction,
  uniTrash,
  uniTrashAlt,
  uniTrees,
  uniTriangle,
  uniTrophy,
  uniTrowel,
  uniTruck,
  uniTruckLoading,
  uniTumblr,
  uniTumblrAlt,
  uniTumblrSquare,
  uniTvRetro,
  uniTvRetroSlash,
  uniTwitter,
  uniTwitterAlt,
  uniUmbrella,
  uniUnamused,
  uniUnderline,
  uniUniversity,
  uniUnlock,
  uniUnlockAlt,
  uniUpload,
  uniUploadAlt,
  uniUsdCircle,
  uniUsdSquare,
  uniUser,
  uniUserArrows,
  uniUserCheck,
  uniUserCircle,
  uniUserExclamation,
  uniUserLocation,
  uniUserMd,
  uniUserMinus,
  uniUserNurse,
  uniUserPlus,
  uniUserSquare,
  uniUserTimes,
  uniUsersAlt,
  uniUtensils,
  uniUtensilsAlt,
  uniVectorSquare,
  uniVectorSquareAlt,
  uniVenus,
  uniVerticalAlignBottom,
  uniVerticalAlignCenter,
  uniVerticalAlignTop,
  uniVerticalDistributeBottom,
  uniVerticalDistributionCenter,
  uniVerticalDistributionTop,
  uniVideo,
  uniVideoQuestion,
  uniVideoSlash,
  uniVirusSlash,
  uniVisualStudio,
  uniVk,
  uniVkAlt,
  uniVoicemail,
  uniVoicemailRectangle,
  uniVolleyball,
  uniVolume,
  uniVolumeDown,
  uniVolumeMute,
  uniVolumeOff,
  uniVolumeUp,
  uniVuejs,
  uniVuejsAlt,
  uniWall,
  uniWallet,
  uniWatch,
  uniWatchAlt,
  uniWater,
  uniWaterDropSlash,
  uniWaterGlass,
  uniWebGrid,
  uniWebGridAlt,
  uniWebSection,
  uniWebSectionAlt,
  uniWebcam,
  uniWeight,
  uniWhatsapp,
  uniWhatsappAlt,
  uniWheelBarrow,
  uniWheelchair,
  uniWheelchairAlt,
  uniWifi,
  uniWifiRouter,
  uniWifiSlash,
  uniWind,
  uniWindMoon,
  uniWindSun,
  uniWindow,
  uniWindowGrid,
  uniWindowMaximize,
  uniWindowSection,
  uniWindows,
  uniWindsock,
  uniWindy,
  uniWordpress,
  uniWordpressSimple,
  uniWrapText,
  uniWrench,
  uniX,
  uniXAdd,
  uniYen,
  uniYenCircle,
  uniYinYang,
  uniYoutube,
  uni500PxMonochrome,
  uniAdobeMonochrome,
  uniAdobeAltMonochrome,
  uniAirplayMonochrome,
  uniAlignMonochrome,
  uniAlignAltMonochrome,
  uniAlignCenterMonochrome,
  uniAlignCenterJustifyMonochrome,
  uniAlignJustifyMonochrome,
  uniAlignLeftMonochrome,
  uniAlignLeftJustifyMonochrome,
  uniAlignLetterRightMonochrome,
  uniAlignRightMonochrome,
  uniAlignRightJustifyMonochrome,
  uniAmazonMonochrome,
  uniAnalysisMonochrome,
  uniAnalyticsMonochrome,
  uniAnchorMonochrome,
  uniAndroidMonochrome,
  uniAndroidAltMonochrome,
  uniAngleDoubleDownMonochrome,
  uniAngleDoubleLeftMonochrome,
  uniAngleDoubleRightMonochrome,
  uniAngleDoubleUpMonochrome,
  uniAngleDownMonochrome,
  uniAngleLeftMonochrome,
  uniAngleRightMonochrome,
  uniAngleRightBMonochrome,
  uniAngleUpMonochrome,
  uniAppleMonochrome,
  uniAppleAltMonochrome,
  uniAppsMonochrome,
  uniArrowCircleDownMonochrome,
  uniArrowCircleLeftMonochrome,
  uniArrowCircleRightMonochrome,
  uniArrowCircleUpMonochrome,
  uniArrowDownLeftMonochrome,
  uniArrowDownRightMonochrome,
  uniArrowUpLeftMonochrome,
  uniArrowUpRightMonochrome,
  uniAtMonochrome,
  uniBagMonochrome,
  uniBarsMonochrome,
  uniBatteryBoltMonochrome,
  uniBatteryEmptyMonochrome,
  uniBehanceMonochrome,
  uniBehanceAltMonochrome,
  uniBingMonochrome,
  uniBitcoinMonochrome,
  uniBitcoinAltMonochrome,
  uniBlackberryMonochrome,
  uniBloggerMonochrome,
  uniBloggerAltMonochrome,
  uniBookmarkMonochrome,
  uniBorderAltMonochrome,
  uniBorderBottomMonochrome,
  uniBorderClearMonochrome,
  uniBorderHorizontalMonochrome,
  uniBorderInnerMonochrome,
  uniBorderLeftMonochrome,
  uniBorderOutMonochrome,
  uniBorderRightMonochrome,
  uniBorderTopMonochrome,
  uniBorderVerticalMonochrome,
  uniBoxMonochrome,
  uniBriefcaseMonochrome,
  uniCalenderMonochrome,
  uniChartMonochrome,
  uniChartPieMonochrome,
  uniCheckMonochrome,
  uniCheckCircleMonochrome,
  uniCheckSquareMonochrome,
  uniCircleMonochrome,
  uniCircleLayerMonochrome,
  uniClinicMedicalMonochrome,
  uniClockMonochrome,
  uniClockEightMonochrome,
  uniClockFiveMonochrome,
  uniClockNineMonochrome,
  uniClockSevenMonochrome,
  uniClockTenMonochrome,
  uniClockThreeMonochrome,
  uniClockTwoMonochrome,
  uniColumnsMonochrome,
  uniCommentMonochrome,
  uniCommentAltMonochrome,
  uniCommentAltDotsMonochrome,
  uniCommentAltMessageMonochrome,
  uniCommentAltPlusMonochrome,
  uniCommentDotsMonochrome,
  uniCommentMessageMonochrome,
  uniCommentPlusMonochrome,
  uniCompressMonochrome,
  uniCornerDownLeftMonochrome,
  uniCornerDownRightMonochrome,
  uniCornerLeftDownMonochrome,
  uniCornerRightDownMonochrome,
  uniCornerUpLeftMonochrome,
  uniCornerUpRightMonochrome,
  uniCoronavirusMonochrome,
  uniCss3Monochrome,
  uniCss3SimpleMonochrome,
  uniCubeMonochrome,
  uniDialpadMonochrome,
  uniDialpadAltMonochrome,
  uniDirectionMonochrome,
  uniDiscordMonochrome,
  uniDockerMonochrome,
  uniDocumentLayoutCenterMonochrome,
  uniDocumentLayoutLeftMonochrome,
  uniDocumentLayoutRightMonochrome,
  uniDownloadAltMonochrome,
  uniDribbbleMonochrome,
  uniDropboxMonochrome,
  uniEllipsisHMonochrome,
  uniEllipsisVMonochrome,
  uniExclamationCircleMonochrome,
  uniExclamationOctagonMonochrome,
  uniExclamationTriangleMonochrome,
  uniFacebookMonochrome,
  uniFacebookFMonochrome,
  uniFacebookMessengerMonochrome,
  uniFacebookMessengerAltMonochrome,
  uniFavoriteMonochrome,
  uniFlipHMonochrome,
  uniFlipHAltMonochrome,
  uniFlipVMonochrome,
  uniFlipVAltMonochrome,
  uniGithubMonochrome,
  uniGithubAltMonochrome,
  uniGitlabMonochrome,
  uniGitlabAltMonochrome,
  uniGoogleMonochrome,
  uniGoogleDriveMonochrome,
  uniGoogleDriveAltMonochrome,
  uniGoogleHangoutsMonochrome,
  uniGoogleHangoutsAltMonochrome,
  uniGooglePlayMonochrome,
  uniGraphBarMonochrome,
  uniGridMonochrome,
  uniGridsMonochrome,
  uniGripHorizontalLineMonochrome,
  uniHeadSideMonochrome,
  uniHeadSideCoughMonochrome,
  uniHeadSideMaskMonochrome,
  uniHipchatMonochrome,
  uniHistoryMonochrome,
  uniHistoryAltMonochrome,
  uniHorizontalAlignLeftMonochrome,
  uniHospitalMonochrome,
  uniHospitalSquareSignMonochrome,
  uniHospitalSymbolMonochrome,
  uniHouseUserMonochrome,
  uniHtml3Monochrome,
  uniHtml3AltMonochrome,
  uniHtml5Monochrome,
  uniHtml5AltMonochrome,
  uniImageVMonochrome,
  uniInstagramMonochrome,
  uniInstagramAltMonochrome,
  uniIntercomMonochrome,
  uniIntercomAltMonochrome,
  uniJavaScriptMonochrome,
  uniKeySkeletonMonochrome,
  uniKeySkeletonAltMonochrome,
  uniKeyholeCircleMonochrome,
  uniKeyholeSquareMonochrome,
  uniKeyholeSquareFullMonochrome,
  uniLayerGroupMonochrome,
  uniLayersAltMonochrome,
  uniLeftIndentMonochrome,
  uniLeftIndentAltMonochrome,
  uniLineMonochrome,
  uniLineSpacingMonochrome,
  uniLinkHMonochrome,
  uniLinkedinMonochrome,
  uniLinkedinAltMonochrome,
  uniLinuxMonochrome,
  uniListUiAltMonochrome,
  uniListUlMonochrome,
  uniLockMonochrome,
  uniLockAccessMonochrome,
  uniLockAltMonochrome,
  uniLockOpenAltMonochrome,
  uniLottiefilesMonochrome,
  uniMasterCardMonochrome,
  uniMediumMMonochrome,
  uniMicroscopeMonochrome,
  uniMicrosoftMonochrome,
  uniMinusSquareFullMonochrome,
  uniMultiplyMonochrome,
  uniObjectGroupMonochrome,
  uniObjectUngroupMonochrome,
  uniOktaMonochrome,
  uniOperaMonochrome,
  uniOperaAltMonochrome,
  uniPadlockMonochrome,
  uniPagelinesMonochrome,
  uniPagerdutyMonochrome,
  uniPaperclipMonochrome,
  uniParagraphMonochrome,
  uniPaypalMonochrome,
  uniPentagonMonochrome,
  uniPlusSquareMonochrome,
  uniPolygonMonochrome,
  uniPreviousMonochrome,
  uniProcessMonochrome,
  uniReactMonochrome,
  uniRecordAudioMonochrome,
  uniRedditAlienAltMonochrome,
  uniRedoMonochrome,
  uniRefreshMonochrome,
  uniRepeatMonochrome,
  uniRightIndentAltMonochrome,
  uniRocketMonochrome,
  uniRulerMonochrome,
  uniRulerCombinedMonochrome,
  uniSanitizerMonochrome,
  uniSanitizerAltMonochrome,
  uniSceneryMonochrome,
  uniScheduleMonochrome,
  uniShieldPlusMonochrome,
  uniSignInAltMonochrome,
  uniSignOutAltMonochrome,
  uniSignalAltMonochrome,
  uniSignalAlt3Monochrome,
  uniSigninMonochrome,
  uniSignoutMonochrome,
  uniSkypeMonochrome,
  uniSkypeAltMonochrome,
  uniSlackMonochrome,
  uniSlackAltMonochrome,
  uniSnapchatAltMonochrome,
  uniSnapchatGhostMonochrome,
  uniSnapchatSquareMonochrome,
  uniSocialDistancingMonochrome,
  uniSortingMonochrome,
  uniSpaceKeyMonochrome,
  uniSquareMonochrome,
  uniSquareFullMonochrome,
  uniSqureShapeMonochrome,
  uniStarMonochrome,
  uniStarHalfAltMonochrome,
  uniStepForwardMonochrome,
  uniStethoscopeMonochrome,
  uniStethoscopeAltMonochrome,
  uniStoreSlashMonochrome,
  uniSubjectMonochrome,
  uniSwiggyMonochrome,
  uniSyncExclamationMonochrome,
  uniSyncSlashMonochrome,
  uniTableMonochrome,
  uniTelegramMonochrome,
  uniTelegramAltMonochrome,
  uniThLargeMonochrome,
  uniTimesCircleMonochrome,
  uniToggleOffMonochrome,
  uniToggleOnMonochrome,
  uniToiletPaperMonochrome,
  uniTriangleMonochrome,
  uniTumblrMonochrome,
  uniTumblrAltMonochrome,
  uniTumblrSquareMonochrome,
  uniTwitterMonochrome,
  uniTwitterAltMonochrome,
  uniUnlockMonochrome,
  uniUnlockAltMonochrome,
  uniUploadAltMonochrome,
  uniUserArrowsMonochrome,
  uniUserMdMonochrome,
  uniUserNurseMonochrome,
  uniVectorSquareMonochrome,
  uniVectorSquareAltMonochrome,
  uniVirusSlashMonochrome,
  uniVisualStudioMonochrome,
  uniVkMonochrome,
  uniVkAltMonochrome,
  uniVuejsMonochrome,
  uniVuejsAltMonochrome,
  uniWebGridMonochrome,
  uniWebGridAltMonochrome,
  uniWebSectionMonochrome,
  uniWebSectionAltMonochrome,
  uniWhatsappMonochrome,
  uniWindowGridMonochrome,
  uniWindowMaximizeMonochrome,
  uniWindowSectionMonochrome,
  uniWindowsMonochrome,
  uniWordpressMonochrome,
  uniWordpressSimpleMonochrome,
  uniWrapTextMonochrome,
  uniYoutubeMonochrome,
])

export { Unicon as AllUniconIcons }
