<template>
  <div class="block">
    <BetSearchBar />
    <BetListCards :bets="this.bets" />
  </div>
</template>

<script>
import BetService from '@/services/BetService.js'
import BetSearchBar from './BetSearchBar.vue'
import BetListCards from './BetListCards.vue'
export default {
  name: 'BetContainer',
  components: {
    BetSearchBar,
    BetListCards,
  },
  // props: {
  // },
  data() {
    return {
      viewmode: 'card',
      bets: null,
    }
  },
  created() {
    BetService.getBets()
      .then((response) => {
        console.log(response.data)
        this.bets = response.data
      })
      .catch((error) => {
        console.log(error)
      })
  },
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
