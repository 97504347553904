import Vuex from 'vuex'
import auth from './modules/auth'
import modal from './modules/modal'
import bet from './modules/bet'

export default new Vuex.Store({
  modules: {
    auth,
    modal,
    bet,
  },
})
