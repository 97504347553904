<template>
  <div class="section">
    <p class="title">Complete your account:</p>
    <div class="field">
      <label class="label">First Name</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="e.g. Joe"
          v-model="firstName"
          @blur="v$.firstName.$touch"
        />
      </div>
      <div
        class="error"
        v-for="error of v$.firstName.$errors"
        :key="error.$uid"
      >
        <p class="help is-danger">{{ error.$message }}</p>
      </div>
    </div>
    <div class="field">
      <label class="label">Last Name</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="e.g. Morales"
          v-model="lastName"
          @blur="v$.lastName.$touch"
        />
      </div>
      <div class="error" v-for="error of v$.lastName.$errors" :key="error.$uid">
        <p class="help is-danger">{{ error.$message }}</p>
      </div>
    </div>
    <div class="field">
      <label class="label">Email</label>
      <div class="control">
        <input
          disabled
          class="input"
          type="email"
          :value="stychEmailList ? stychEmailList[0] : null"
        />
      </div>
    </div>
    <br />
    <div class="field">
      <label class="label">Order Password (min. of 8 characters)</label>
      <p class="control">
        <input
          class="input"
          type="password"
          placeholder="Password"
          v-model="password"
          @blur="v$.password.$touch"
        />
      </p>
      <div class="error" v-for="error of v$.password.$errors" :key="error.$uid">
        <p class="help is-danger">{{ error.$message }}</p>
      </div>
    </div>
    <div class="field">
      <label class="label">Confirm Password</label>
      <p class="control">
        <input
          class="input"
          type="password"
          placeholder="Password"
          v-model="sameAsPassword"
          @blur="v$.sameAsPassword.$touch"
        />
      </p>
      <p class="help is-danger">
        <strong>Keep this safe!</strong>
        This is your digital signature when placing orders.
      </p>
      <div
        class="error"
        v-for="error of v$.sameAsPassword.$errors"
        :key="error.$uid"
      >
        <p class="help is-danger">{{ error.$message }}</p>
      </div>
      <br />
    </div>
    <div class="field">
      <div class="control">
        <label class="checkbox">
          <input
            type="checkbox"
            @blur="v$.agreedTerms.$touch"
            @click="checkbox"
          />
          I agree to the <a href="#">terms and conditions</a>
        </label>
      </div>
    </div>
    <div class="field is-grouped">
      <div class="control">
        <button class="button is-link" :disabled="v$.$invalid" @click="submit">
          Submit
        </button>
      </div>
      <!-- <div class="control">
        <button class="button is-link is-light" @click="cancel">Cancel</button>
      </div> -->
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, sameAs, minLength } from '@vuelidate/validators'
import UserService from '@/services/UserService.js'
export default {
  name: 'CreateUser',
  components: {},
  computed: {
    stychEmailList() {
      return this.$store.state.auth.stychEmailList
    },
    stychId() {
      return this.$store.state.auth.stychId
    },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      password: '',
      sameAsPassword: '',
      agreedTerms: false,
    }
  },
  validations() {
    return {
      firstName: { required },
      lastName: { required },
      password: {
        required,
        minLengthValue: minLength(8),
      },
      sameAsPassword: { sameAs: sameAs(this.password) },
      agreedTerms: { sameAs: sameAs(true) },
    }
  },
  methods: {
    checkbox() {
      this.agreedTerms = !this.agreedTerms
      console.log(this.agreedTerms)
    },
    submit() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        // this.submitStatus = 'ERROR'
        console.log('invalid:', this.v$.$invalid)
        console.log(this.v$)
      } else {
        // do your submit logic here
        // this.submitStatus = 'PENDING'
        let userCreate = {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.stychEmailList[0], // FROM STORE
          stych_id: this.stychId, // FROM STORE
          order_password: this.password,
        }
        UserService.createUser(userCreate)
          .then((res) => {
            this.$store
              .dispatch('auth/loginUser', res)
              .then(this.$router.replace({ name: 'Home' }))
          })
          .catch((error) => console.log(error))
        this.v$.$reset()
        this.userCreate = null
      }
    },
    // cancel() {
    //   this.$router.replace({ name: 'Home' })
    // },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
