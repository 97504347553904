<template>
  <div>
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Sign up or log in</p>
        <button class="delete" aria-label="close" @click="closeModal"></button>
      </header>
      <section class="modal-card-body" v-if="isSubmited">
        <p class="mt-6 mb-6 has-text-centered">
          Check your email at <strong>{{ name }}</strong> to continue login
        </p>
      </section>
      <section class="modal-card-body" v-if="!isSubmited">
        <p>Sign up or login with your email, no password needed</p>
        <br />
        <div class="field">
          <label class="label">Email</label>
          <div class="control has-icons-right">
            <input
              class="input"
              :class="{ 'is-danger': v$.name.$error }"
              type="email"
              placeholder="youremail@domain.com"
              v-model="name"
              @blur="v$.name.$touch"
            />
            <span class="icon is-small is-right" v-if="v$.name.$error">
              <unicon name="exclamation" fill="gray"></unicon>
            </span>
          </div>
          <div class="error" v-for="error of v$.name.$errors" :key="error.$uid">
            <p class="help is-danger">{{ error.$message }}</p>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot" v-if="!isSubmited">
        <button
          class="button is-success"
          @click="submit"
          :disabled="v$.$invalid"
        >
          Sign in with email
        </button>
      </footer>
      <footer class="modal-card-foot" v-if="isSubmited">
        <p>
          Didn't get it?
          <a @click="submit">Click here to resend confirmation link</a>
        </p>
      </footer>
    </div>
    <div v-if="v$.name.$error">Name field has an error.</div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import AuthService from '@/services/AuthService.js'

export default {
  name: 'ModalLogin',
  components: {},
  props: {
    showModal: Boolean,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      name: null,
      isSubmited: false,
    }
  },
  validations() {
    return {
      name: {
        required,
        email,
      },
    }
  },

  created() {},
  methods: {
    submit() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        // this.submitStatus = 'ERROR'
        console.log(this.v$.$invalid)
      } else {
        // this.submitStatus = 'PENDING'
        AuthService.login(this.name)
          .then((response) => {
            console.log(response)
            // console.log(response.data.userID)
            this.isSubmited = true
          })
          .catch((error) => console.log(error))
      }
    },
    closeModal() {
      this.v$.$reset()
      this.name = null
      this.isSubmited = null
      this.$store.dispatch('modal/toggleModal')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
