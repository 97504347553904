import AuthService from '@/services/AuthService.js'
import UserService from '@/services/UserService.js'

const namespaced = true

// mock state
const state = {
  isLoggedIn: true,
  user: {
    email: 'lcastro.caio@gmail.com',
    first_name: 'Caio',
    last_name: 'Castro',
    id: 1,
    stych_id: 'user-test-83aafc24-d3a9-4b1d-9c87-c365ea7f5be2',
    reputation: 1,
    is_active: true,
    hh_id: '0.0.2939878-mcdzc',
  },
  stychId: 'user-test-83aafc24-d3a9-4b1d-9c87-c365ea7f5be2',
  stychEmailList: ['lcastro.caio@gmail.com'],
  sessionToken: 'yUrvihckefMnVWCqONvGq0U6F_eBMyXy98epPrQE5ehc',
}

// const state = {
//   isLoggedIn: false,
//   user: {},
//   stychId: null,
//   stychEmailList: null,
//   sessionToken: null,
// }

const getters = {
  isLoggedIn: (state) => state.isLoggedIn,
  user: (state) => state.user,
  stychId: (state) => state.stychId,
  stychEmailList: (state) => state.stychEmailList,
  sessionToken: (state) => state.sessionToken,
}

const actions = {
  async toSession({ commit }, stych_token) {
    try {
      await AuthService.authMagicLink(stych_token).then(({ data }) => {
        console.log(data)
        commit('setSession', data)
      })
      // await AuthService.getStychUserById(state.stychId).then(({ data }) => {
      //   commit('setEmail', data)
      // })
    } catch (error) {
      this.$router.replace({ name: 'Home' })
      console.warn('magic link failed')
      console.log(error)
    }
  },

  async loginUser({ commit }, { data }) {
    try {
      await commit('setUser', data)
    } catch (error) {
      console.log(error)
    }
  },

  async fetchUser({ commit }, stychId) {
    await UserService.readUser(stychId).then(({ data }) =>
      commit('setUser', data)
    )
  },

  async logoutUser({ commit }, token) {
    await AuthService.logout(token)
    commit('logoutUserState')
  },
}

const mutations = {
  setSession(state, data) {
    state.stychId = data.user_id
    state.stychEmailList = data.emails
    state.sessionToken = data.token
  },

  setUser(state, data) {
    state.user = data
    state.isLoggedIn = true
  },

  logoutUserState(state) {
    state.isLoggedIn = false
    state.user = {}
    state.stychId = null
    state.stychEmailList = null
    state.sessionToken = null
  },
}

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
