import BetService from '@/services/BetService'

const namespaced = true

const state = {
  betCreationStatus: null,
  bet: {},
}

const getters = {
  betCreationStatus: (state) => state.betCreationStatus,
  bet: (state) => state.bet,
}

const actions = {
  async clearBetCreate({ commit }) {
    commit('setStatus', null)
    commit('setBet', {})
  },
  async createBet({ commit }, betPackage) {
    try {
      commit('setStatus', 'pending')
      await BetService.createBet(
        betPackage.user_email,
        betPackage.createBet
      ).then(({ data }) => {
        console.log(data)
        commit('setBet', data)
        commit('setStatus', 'success')
      })
    } catch (error) {
      commit('setStatus', 'ERROR')
      console.log('Could not create bet:', error)
    }
  },

  // async fetchUser({ commit }, stychId) {
  //   await UserService.readUser(stychId).then(({ data }) =>
  //     commit('setUser', data)
  //   )
  // },
}

const mutations = {
  setStatus(state, data) {
    state.betCreationStatus = data
  },

  setBet(state, data) {
    state.bet = data
  },
}

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
