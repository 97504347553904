<template>
  <div class="columns is-multiline is-5 is-variable">
    <bet-card
      class="column is-3-widescreen is-4-desktop is-6-tablet"
      v-for="(bet, index) in bets"
      :key="index"
      :bet="bet"
    />
  </div>
</template>

<script>
import BetCard from './BetCard.vue'
export default {
  components: { BetCard },
  name: 'BetListCards',
  props: {
    bets: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {}
  },

  methods: {
    betsFromApi() {
      console.log(this.viewmode)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
