import axios from 'axios'

// import router from '@/router/index.js'
// const COOKIE_EXPIRED_MSG = 'Token has expired'

const authService = axios.create({
  baseURL: 'http://localhost:8000/auth',
  withCredentials: true,
  // xsrfCookieName: 'csrf_access_token',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export default {
  login(info) {
    return authService.post('/login?email=' + info)
  },
  logout(token) {
    return authService.post('/logout?token=' + token)
  },
  authMagicLink(token) {
    return authService.get('/?token=' + token)
  },
  getStychUserById(stych_id) {
    return authService.get('/stych_user/' + stych_id)
  },
}
