<template>
  <!-- class="modal"  -->
  <div class="modal" :class="{ 'is-active': showModal }">
    <div>
      <div v-if="modalMode == 'createBet'">
        <ModalLogin v-if="!isLoggedIn" @toggleModal="toggleModal" />
        <ModalNewBet v-if="isLoggedIn" @toggleModal="toggleModal" />
      </div>
      <div v-else>
        <ModalLogin v-if="!isLoggedIn" @toggleModal="toggleModal" />
        <ModalLogout v-if="isLoggedIn" @toggleModal="toggleModal" />
      </div>
    </div>
  </div>
</template>

<script>
import ModalLogin from './ModalLogin.vue'
import ModalLogout from './ModalLogout.vue'
import ModalNewBet from './ModalNewBet.vue'

export default {
  name: 'ModalContainer',
  components: { ModalLogin, ModalLogout, ModalNewBet },
  computed: {
    showModal() {
      return this.$store.state.modal.showModal
    },
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn
    },
    modalMode() {
      return this.$store.state.modal.modalMode
    },
  },
  methods: {
    toggleModal() {},

    closeModal() {
      this.v$.$reset()
      this.name = null
      this.isSubmited = null
      this.$emit('toggleModal')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
