<template>
  <!-- Main container -->
  <nav class="level level-is-shrinkable">
    <!-- Left side -->
    <div class="level-left">
      <div class="level-item">
        <p class="subtitle is-5"><strong>123</strong> active bets</p>
      </div>
      <div class="level-item">
        <div class="field has-addons">
          <p class="control">
            <input class="input" type="text" placeholder="Find a bet" />
          </p>
          <p class="control">
            <!-- TODO: no icon showing at search button -->
            <unicon name="search" class="button"></unicon>
          </p>
          <!-- <p class="control">
            <unicon name="filter" class="button" style="max-height: 55px"
              >Search</unicon
            >
          </p> -->
        </div>
      </div>
    </div>

    <!-- Right side -->
    <div class="level-right">
      <p class="level-item"><a>All</a></p>
      <p class="level-item"><a>Sports</a></p>
      <p class="level-item"><a>Politics</a></p>
      <p class="level-item"><a>Culture</a></p>
      <p class="level-item">
        <a
          class="button is-success"
          @click="this.$store.dispatch('modal/createBet')"
          >Create Bet</a
        >
      </p>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'BetSearchBar',
  components: {},
  // props: {
  // },
  data() {
    return {}
  },
  created() {},
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.level-is-shrinkable .level-left,
.level-is-shrinkable .level-right,
.level-is-shrinkable .level-item {
  flex-shrink: 1;
}
</style>
