const namespaced = true

const state = {
  showModal: false,
  modalMode: null,
}

const getters = {
  showModal: (state) => state.showModal,
}

const actions = {
  async toggleModal({ commit }) {
    commit('setModal')
  },
  async createBet({ commit }) {
    await commit('createBetModalMode')
    commit('setModal')
  },
}

const mutations = {
  setModal(state) {
    state.showModal = !state.showModal
  },
  createBetModalMode(state) {
    state.modalMode = 'createBet'
  },
  nullModalMode(state) {
    state.modalMode = null
  },
}

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
