<template>
  <div v-if="bet">
    <p>This is a bet detail</p>
  </div>
</template>

<script>
import BetService from '@/services/BetService.js'
export default {
  name: 'BetDetails',
  components: {},
  props: ['id'],
  data() {
    return {
      bet: null,
    }
  },
  created() {
    BetService.getBetById(this.id)
      .then((response) => (this.bet = response.data))
      .catch((error) => console.log(error))
  },
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
