<template>
  <nav
    class="navbar is-fixed-top is-spaced is-white has-shadow"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <router-link class="navbar-item" to="/">
        <img
          class="py-2 px-2"
          src="@/assets/morales-dice.svg"
          width="112"
          height="28"
          style="max-height: 55px"
        />
        <h1 class="title">Morales.bet</h1>
      </router-link>
      <!-- add 'is-active' to navbar-burguer on click to change to an X -->
      <a
        id="burger"
        role="button"
        class="navbar-burger py-6 px-6"
        :class="{ 'is-active': isActive }"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarMenuOptions"
        @click="onClickMenu"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="nav-links" class="navbar-menu" :class="{ 'is-active': isActive }">
      <div class="navbar-end">
        <router-link class="navbar-item is-size-5" :to="{ name: 'Home' }">
          Home
        </router-link>
        <a
          class="navbar-item is-size-5"
          @click="this.$store.dispatch('modal/toggleModal')"
          >{{ LoggedInInfo }}</a
        >
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Navbar',
  components: {},
  // props: {
  // },
  data() {
    return {
      isActive: false,
    }
  },
  computed: {
    LoggedInInfo() {
      return this.$store.state.auth.isLoggedIn ? 'Logout' : 'Login'
    },
    stychId() {
      return this.$store.state.auth.stychId
    },
  },
  created() {},
  methods: {
    onClickMenu() {
      this.isActive = !this.isActive
    },
    linkClick() {
      console.log('click')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
