<template>
  <div>
    <div class="section">
      <!-- <p>{{ auth }}</p>
      <p>{{ modal }}</p> -->
      <BetContainer />
    </div>
  </div>
</template>

<script>
import BetContainer from '../components/bets/BetContainer.vue'
export default {
  components: { BetContainer },
  name: 'HomePage',
  props: {
    msg: String,
  },
  computed: {
    auth() {
      return this.$store.state.auth
    },
    modal() {
      return this.$store.state.modal
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
