<template>
  <div class="column">
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          <a class="buy mr-3">49c</a>/<a class="sell ml-3">54c</a>
        </p>
        <button class="card-header-icon" aria-label="more options">
          <span class="icon">
            <!-- <unicon name="book-reader"></unicon> -->
            <!-- <unicon name="trophy"></unicon> -->
            <unicon name="bright"></unicon>
          </span>
        </button>
      </header>
      <div class="card-content">
        <div class="content">
          <p class="title">Bolsonaro 2020</p>
          <p class="subtitle">Topic ID 0.0.27728</p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec
          iaculis mauris.
          <a href="#">@bulmaio</a>. <a href="#">#css</a>
          <a href="#">#responsive</a>
          <br />
          <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
        </div>
      </div>
      <footer class="card-footer">
        <a href="#" class="card-footer-item is-primary">Buy</a>
        <a href="#" class="card-footer-item">Sell</a>
        <router-link :to="{ name: 'BetDetails', params: { id: bet.id } }">
          <a class="card-footer-item">Details</a>
        </router-link>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BetCard',
  components: {},
  props: {
    bet: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  created() {},
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.buy {
  color: $secondarycolor;
}
.sell {
  color: $sellcolor;
}
</style>
