<template>
  <div class="column is-11">
    <div class="control">
      <input
        class="input"
        type="text"
        placeholder="Lewis should have the highest no. of points after Yas Marina (Abu Dhabi) race"
        :value="condition"
        @blur="v$.condition.$touch"
        @input="$emit('update:condition', $event.target.value)"
      />
    </div>
    <div class="error" v-for="error of v$.condition.$errors" :key="error.$uid">
      <p class="help is-danger">{{ error.$message }}</p>
    </div>
  </div>
  <div class="column is-1">
    <div class="control">
      <!-- TODO: no icon showing at search button -->
      <div v-if="condition">
        <div class="columns is-gapless">
          <div class="column is-6">
            <p
              name="search"
              class="button"
              :class="{ active: isActive }"
              @click="$emit('new-input')"
            >
              +
            </p>
          </div>
        </div>
      </div>
      <div v-if="!condition">
        <div class="columns">
          <div class="column is-12">
            <p name="search" class="button" @click="$emit('close-input')">X</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  name: 'AddInput',
  components: {},
  props: {
    condition: String,
  },
  emits: ['new-input', 'close-input', 'update:condition'],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      isActive: false,
    }
  },
  validations() {
    return {
      condition: { required },
    }
  },

  created() {},
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
