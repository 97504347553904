<template>
  <div>
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title" v-if="!isSubmited">Create new bet</p>
        <p class="modal-card-title" v-if="isSubmited">Bet Created!</p>
        <button class="delete" aria-label="close" @click="closeModal"></button>
      </header>
      <section class="modal-card-body" v-if="isSubmited">
        <p class="mt-6 mb-6 has-text-centered">
          Bet id. {} created successfully. <a>Go to trading floor</a>
        </p>
      </section>
      <section class="modal-card-body" v-if="!isSubmited">
        <div class="field">
          <label class="label">Bet Title</label>
          <div class="control">
            <input
              class="input"
              type="text"
              placeholder="e.g. Lewis Hamilton F1 Champion 2021"
              v-model="title"
              @blur="v$.title.$touch"
            />
          </div>
          <div
            class="error"
            v-for="error of v$.title.$errors"
            :key="error.$uid"
          >
            <p class="help is-danger">{{ error.$message }}</p>
          </div>
        </div>
        <div class="field">
          <label class="label">Category</label>
          <div class="select">
            <select v-model="cat_selected" @blur="v$.cat_selected.$touch">
              <option v-for="cat in categories" :key="cat">
                {{ cat }}
              </option>
            </select>
          </div>
          <div
            class="error"
            v-for="error of v$.cat_selected.$errors"
            :key="error.$uid"
          >
            <p class="help is-danger">{{ error.$message }}</p>
          </div>
        </div>
        <div class="field">
          <label class="label">Description</label>
          <div class="control">
            <textarea
              class="textarea"
              placeholder="e.g. Lewis Hamilton will land the 2021 Formula 1 World Championship. . ."
              v-model="description"
              @blur="v$.description.$touch"
            >
            </textarea>
          </div>
          <div
            class="error"
            v-for="error of v$.description.$errors"
            :key="error.$uid"
          >
            <p class="help is-danger">{{ error.$message }}</p>
          </div>
        </div>
        <div class="field">
          <label class="label">Winning Conditions</label>
          <div class="columns is-multiline is-gapless">
            <AddInput
              v-for="cond in conditions"
              v-model:condition="cond.text"
              v-bind:key="cond.id"
              @new-input="newInput"
              @close-input="closeInput"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Closes at</label>
              <DatePicker
                v-model="dateClose"
                :min-date="new Date()"
                mode="dateTime"
                @click="v$.dateClose.$touch"
                @blur="v$.dateClose.$touch"
              />
              <div
                class="error"
                v-for="error of v$.dateClose.$errors"
                :key="error.$uid"
              >
                <p class="help is-danger">{{ error.$message }}</p>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="label">Settles at</label>
              <DatePicker
                v-model="dateSettle"
                mode="dateTime"
                :min-date="new Date()"
                @blur="v$.dateSettle.$touch"
                @click="v$.dateSettle.$touch"
              />
              <div
                class="error"
                v-for="error of v$.dateSettle.$errors"
                :key="error.$uid"
              >
                <p class="help is-danger">{{ error.$message }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot" v-if="isSubmited"></footer>
      <footer class="modal-card-foot" v-if="!isSubmited">
        <div class="field">
          <label class="label">Sign and Create</label>
          <div class="columns is-gapless">
            <div class="column">
              <p class="control">
                <input
                  class="input"
                  type="password"
                  placeholder="Password"
                  v-model="password"
                  @blur="v$.password.$touch"
                />
              </p>
              <div
                class="error"
                v-for="error of v$.password.$errors"
                :key="error.$uid"
              >
                <p class="help is-danger">{{ error.$message }}</p>
              </div>
            </div>
            <div class="column">
              <button
                class="button is-success"
                @click="submit"
                :disabled="v$.$invalid"
              >
                Create bet!
              </button>
            </div>
          </div>
        </div>
        <!-- <button class="button is-success" @click="debug">DEBUG!</button> -->
      </footer>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength, minLength, minValue } from '@vuelidate/validators'
import { DatePicker } from 'v-calendar'
// import AuthService from '@/services/AuthService.js'
import AddInput from '@/components/forms/AddInput.vue'
export default {
  name: 'ModalNewBet',
  components: { DatePicker, AddInput },
  props: {
    showModal: Boolean,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      title: '',
      description: '',
      conditions: [{ id: 0, text: '' }],
      dateClose: new Date(),
      dateSettle: new Date(),
      password: '',
      isSubmited: false,
      submitStatus: [],
      categories: ['sports', 'politics', 'culture'],
      cat_selected: '',
    }
  },
  validations() {
    return {
      title: {
        required,
        minLenghtValue: minLength(5),
        maxLengthValue: maxLength(200),
      },
      description: {
        required,
        maxLengthValue: maxLength(1000),
      },
      conditions: { required },
      password: { required },
      cat_selected: { required },
      dateClose: { required },
      dateSettle: {
        required,
        minValue: minValue(this.dateClose),
      },
    }
  },
  // computed: {
  //   hh_user_id() {
  //     return
  //   },
  // },
  created() {},
  methods: {
    submit() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        // this.submitStatus = 'ERROR'
        console.log(this.v$.$invalid)
      } else {
        let createBet = {
          title: this.title,
          category_name: this.cat_selected,
          description: this.description,
          winning_cond: this.conditions,
          close_date: this.dateClose,
          settlement_date: this.dateSettle,
          signature_key: this.password,
        }
        let hh_user_id = this.$store.state.auth.user.email
        let betPackage = {
          hh_user_id,
          createBet,
        }
        console.log(betPackage)
        this.submitStatus = 'PENDING'
        this.isSubmited = true
        this.$store.dispatch('bet/createBet', betPackage)
      }
    },
    debug() {
      console.log(this.v$.dateSettle.$errors)
    },
    closeModal() {
      this.v$.$reset()
      this.isSubmited = null
      this.$store.dispatch('modal/toggleModal')
    },
    newInput() {
      this.conditions.push({ id: this.conditions.length, text: '' })
    },
    closeInput() {
      if (this.conditions.length > 1) {
        this.conditions.pop()
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vc-container {
  display: block !important;
  width: 100% !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  font-family: 'Cutive Mono', monospace !important;
  line-height: 1.5 !important;
  border-radius: 0.2rem !important;
}
</style>
